import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SPLIT_INDEX, urlEntities } from '../../../../Constants/constants';
import { noBackBrowsing } from '../../../../Container/Helpers/commonHelpers';
import DashboardNavbar from './dashboardNavbar';
import FormNavbar from './formNavbar';

const Navbar = () => {
    const { pathname } = useLocation();

    // Prevent browser back button.
    useEffect(() => {
        noBackBrowsing(pathname);
    },[pathname]);

    const activeNavbar = () => {
        if (
            urlEntities.formNav.includes(pathname.split('/')[SPLIT_INDEX.FIRST])
        ) {
            return <FormNavbar />;
        } else if (
            urlEntities.dashboardNav.includes(
                pathname.split('/')[SPLIT_INDEX.FIRST]
            )
        ) {
            return <DashboardNavbar />;
        }
        return <FormNavbar />; // TODO: Replace with <StaticNavbar/> when marketing page jsx available.
    };

    return <>{activeNavbar()}</>;
};

export default Navbar;
