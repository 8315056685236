import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { DEFAULT_VALUE, MAX_CHAR_LENGTH } from '../../../Constants/constants';
import { updateProfileInitialValues } from '../../../Constants/initialValues';
import { SUCCESS_TOAST } from '../../../Constants/messages';
import { updateProfileSchema } from '../../../Constants/validationSchema';
import { getErrorClassName, updateProfilePayload } from '../../../Container/Helpers/commonHelpers';
import { AuthServices } from '../../../Container/Services/authServices';
import { UserServices } from '../../../Container/Services/userProfileServices';
import { getUserProfile } from '../../../DataSources/Redux/Slices/User/userSlice';
import { useWebDispatch } from '../../../DataSources/Redux/Store/hooks';
import { UpdateProfileType } from '../../../Entities/propsTypes';
import LoadingButton from '../Shared/Buttons/loadingButton';
import FormikController from '../Shared/Formik/formikController';
import PhoneInputField from '../Shared/InputFields/phoneInputField';
import { getIsoCode } from '../Shared/InputFields/useIsoCode';
import ProfileDetailsModal from '../Shared/Modals/profileDetailsModal';
import { successToast } from '../Shared/Toastify/toaster';

const UpdateProfile = (props: any) => {
  const { profileUpdate, setActive, setProfileUpdate, data } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useWebDispatch();

  // Getting iso2 code from the country code.
  const { isoCode } = getIsoCode(data?.country_code);

  // Update profile data for pre-filled input.
  const updateProfileData = {
    firstName: data?.first_name,
    lastName: data?.last_name,
    email: data?.email,
    phoneNumber: data?.phone_number,
  };

  // Setting updated value in the input field.
  const setFormValue = () => {
    if(data) {
        return {
          firstName: data?.first_name,
          lastName: data?.last_name,
          email: data?.email,
          phoneNumber: data?.phone_number,
        };
    } else {
        return updateProfileInitialValues;
    }
  };

  // Form submit function for update profile data.
  const setUpdatedData = (values: UpdateProfileType) => {
    if(Object.entries(updateProfilePayload(values, updateProfileData)).length > DEFAULT_VALUE) {
    setLoading(true);
    UserServices.updateUserProfile(updateProfilePayload(values, updateProfileData)).then((res) => {
        if(res) {
            setProfileUpdate(false);
            dispatch(getUserProfile());
            successToast(SUCCESS_TOAST.PROFILE_UPDATED);
        };
    }).finally(() => setLoading(false));
    }
  };

  return (
    <>
      {profileUpdate && (
        <ProfileDetailsModal
          headingClass='right-align'
          heading='Update Profile'
          backNavigate={() => {
            setActive('');
            setProfileUpdate(false);
          }}
        >
          <Formik
            initialValues={setFormValue()}
            validationSchema={updateProfileSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values: UpdateProfileType) => setUpdatedData(values)}
          >
            {({ touched, errors, values }) => (
              <Form className='profile-form'>

                {/* First name input field */}
                <div className='row mt-2'>
                  <div className='form-col'>
                    <FormikController
                      maxLength={MAX_CHAR_LENGTH.INPUT_FIELD}
                      control='input'
                      name='firstName'
                      label='First Name'
                      value={values.firstName}
                      className={getErrorClassName(
                        touched.firstName,
                        errors.firstName
                      )}
                      hidden={false}
                      req={true}
                    />
                  </div>
                </div>

                {/* Last name input field */}
                <div className='row mt-2'>
                  <div className='form-col'>
                    <FormikController
                      maxLength={MAX_CHAR_LENGTH.INPUT_FIELD}
                      control='input'
                      name='lastName'
                      label='Last Name'
                      className={getErrorClassName(
                        touched.lastName,
                        errors.lastName
                      )}
                      hidden={false}
                      req={true}
                    />
                  </div>
                </div>

                <div className='row mt-2 is-disabled'>
                  <div className='form-col phone-input'>
                    <PhoneInputField disabled={true} defaultCountry={isoCode.toLowerCase()}/>
                    <FormikController
                      disabled
                      control='input'
                      maxLength={MAX_CHAR_LENGTH.PHONE_NUMBER}
                      name='phoneNumber'
                      label='Phone Number'
                      className='form-control'
                      hidden={false}
                      req={true}
                    />
                  </div>
                </div>

                {/* Email input field */}
                <div className='row mt-2'>
                  <div className='form-col'>
                    <div className='input-action'>
                      <FormikController
                        maxLength={MAX_CHAR_LENGTH.EMAIL}
                        control='input'
                        name='email'
                        label='Email'
                        className={getErrorClassName(
                          touched.email,
                          errors.email
                        )}
                        hidden={false}
                        req={true}
                      />
                      {!data?.email_verified_at && (
                        <span
                          className='input-btn'
                          onClick={() => {
                            AuthServices.verifyEmailSend().then(() => {
                              successToast(SUCCESS_TOAST.VERIFY_EMAIL_SEND);
                            });
                          }}
                        >
                          Verify
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <LoadingButton
                  className='btn btn-primary w-100'
                  isLoading={loading}
                  icon={true}
                  title='Update'
                  disable={Object.entries(updateProfilePayload(values, updateProfileData)).length === DEFAULT_VALUE}
                />
                <button
                  className='btn btn-danger w-100 mt-3'
                  onClick={() => {
                    setActive('');
                    setProfileUpdate(false);
                  }}
                >
                  Cancel
                </button>
              </Form>
            )}
          </Formik>
        </ProfileDetailsModal>
      )}
    </>
  );
};

export default UpdateProfile;
