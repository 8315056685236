import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../Slices/Auth/authSlice';
import propertySlice from '../Slices/Property/propertySlice';
import userSlice from '../Slices/User/userSlice';
import projectSlice from '../Slices/Project/projectSlice';
import zillowSlice from '../Slices/Property/zillowSlices';
import  bankSlice  from '../Slices/Bank/createAccountSlice';
import tenureSlice from '../Slices/Bank/tenureSlice';
import projectListSlice from '../Slices/Project/projectListing';
import projectFilterSlice from '../Slices/Project/projectFilterSlice';
import customSegragation from '../Slices/Project/customSegregation';
import cardDetailsSlice  from '../Slices/DebitCard/cardDetails';
import transactionSlice from '../Slices/Transactions/transactionSlice';
import userDataSlice from '../Slices/User/userDataSlice';
import loaderSlice  from '../Slices/Loader/loadingSlice';
import propertyDataSlice from '../Slices/Property/propertyDataSlice';
import zillowDataSlice from '../Slices/Property/zillowDataSlice';

const reducer = {
  auth: authSlice,
  property: propertySlice,
  profile: userSlice,
  project: projectSlice,
  zillow: zillowSlice,
  bank :bankSlice,
  tenure: tenureSlice,
  projectData: projectListSlice,
  projectFilter: projectFilterSlice,
  segregationEstimate: customSegragation,
  debitCardDetails: cardDetailsSlice,
  transactions: transactionSlice,
  profileData: userDataSlice,
  loader: loaderSlice,
  propertyData: propertyDataSlice,
  zillowData: zillowDataSlice
};

// Global store of the application
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
