import React from 'react';
import { Spinner } from 'react-bootstrap';
import btnIcon from '../../../Assets/Images/btn-arrow.svg';

const LoadingButton = (props: {
  isLoading: boolean;
  onClick?: any;
  title: string | undefined;
  onSubmit?: () => void;
  icon?: boolean;
  className?: string;
  id?: string;
  disable?: boolean;
  greenButton?: any
  subtitle?: string;
  mobileSubtitle?: string;
}) => {

  return (
    <button
      type='submit'
      className={props.className ? props.className : 'btn btn-primary mt-0'}
      onClick={props.onClick}
      onSubmit={props.onSubmit}
      disabled={props.isLoading || props.disable}
      data-testid={props.id}
    >
      {props.title} <span className='sm-hide'>{props.subtitle}</span> {props.mobileSubtitle}
      {props.isLoading && (
        <div className='btn-loader'>
          <Spinner animation='border' />
        </div>
      )}
      {!props.icon && <img className='btn-icon' src={props.greenButton ? props.greenButton : btnIcon} alt='icon' />}
    </button>
  );
};

export default LoadingButton;
