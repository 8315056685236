import { UserRequest } from '../../DataSources/ApiServices/userRequest';
import { UpdateProfileType } from '../../Entities/propsTypes';

export class UserServices {
  private static readonly userServices: UserRequest = new UserRequest();

  static getUserData = async() => {
    return this.userServices.getUserProfile();
  };

  static updateUserProfile = async(data: UpdateProfileType) => {

    const profilePayload = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email
    };

    return this.userServices.profileUpdate(profilePayload);
  };
}
