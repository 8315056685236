import { Country }  from 'country-state-city';

export const getIsoCode = (countryCode: string) => {
    let isoCode = '';

    // Countries data.
    const countriesData = Country.getAllCountries();

    // Getting iso code from the countries data.
    countriesData && countriesData.forEach((value: {isoCode: string, phonecode: string}) => {

        if( value.phonecode === countryCode ) {
            isoCode = value.isoCode;
        }
        return isoCode;
    });

    return {
        isoCode
    };
};
