import React from 'react';
import Navbar from '../../UI/Components/Shared/Navbar/navbar';

const FormsLayout = (props: { children: JSX.Element }) => {
 return (
  <div className='main-layout'>
   <Navbar />
   {props.children}
  </div>
 );
};

export default FormsLayout;
