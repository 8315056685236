import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthServices } from '../../../../../Container/Services/authServices';
import { deleteAllCookies } from '../../../../../Container/Services/cookiesServices';
import { COMMONPATHS } from '../../../../../Routes/Config/paths';

const Logout = () => {
    const navigate = useNavigate();

  // Funtion for user logging out from the portal.
  const onLogout = () => {
    AuthServices.logoutService().then((res) => {
      if (res) {
        deleteAllCookies();
        localStorage.clear();
        navigate(COMMONPATHS.LOGIN, { replace: true });
      }
    });
  };

  return (
    <div className='profile-link-row logout-btn'>
      <Dropdown onSelect={() => onLogout()}>
        <Dropdown.Toggle className='profile-link-btn' id='logout'>
          Log Out
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>Log Out of the app</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Logout;
