import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AUTH_API_CONSTANTS } from '../../../../Constants/apiConstants';
import { reducerEnums } from '../../../../Constants/reducerEnums';
import { setCookie } from '../../../../Container/Services/cookiesServices';
import { IUserStoreType } from '../../../../Entities/authStoreTypes';
import { successToast } from '../../../../UI/Components/Shared/Toastify/toaster';
import { PostRequest } from '../../../ApiServices/request';

const initialState: IUserStoreType = {
  isLoading: false,
  user: null,
  isError: false,
  isSuccess: false,
  message: null,
};

export const otpVerification = createAsyncThunk(
  reducerEnums.otpVerify,
  async (otp: {}, thunkAPI) => {
    try {
      const { data, status } = await PostRequest(AUTH_API_CONSTANTS.OTP_VERIFICATION, otp);
      successToast(data?.message);
      return { data, status };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resendOtp = createAsyncThunk(
  reducerEnums.resendOtp,
  async (_data, thunkAPI) => {
    try {
      const { data, status } = await PostRequest(AUTH_API_CONSTANTS.RESEND_OTP);
      successToast(data?.message);
      return { data, status };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const changePhoneNumber = createAsyncThunk(
  reducerEnums.changePhoneNumber,
  async (phoneNumber: {}, thunkAPI) => {
    try {
      const { data, status } = await PostRequest(AUTH_API_CONSTANTS.CHANGE_PHONE_NUMBER, phoneNumber);
      successToast(data?.message);
      return { data, status };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: reducerEnums.auth,
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(otpVerification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(otpVerification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(otpVerification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(changePhoneNumber.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePhoneNumber.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(changePhoneNumber.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
