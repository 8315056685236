import React, { useEffect, useState } from 'react';
import logoIcon from '../../Assets/Images/logo-white.png';

const NoInternetConnection = (props: { children: JSX.Element }) => {
  // State variable holds the state of the internet connection.
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  // If user is online, return the child component else return a custom component.
  if (isOnline) {
    return props.children;
  } else {
    return (
      <div className='body-container'>
      <div className='password-layout bank-success'>
        <div className='fixed-header'>
          <div className='container'>
            <img src={logoIcon} alt='logo' />
          </div>
        </div>
        <div className='password-card email-card'>
        <div className='password-title'>No Internet Connection</div>
          <div className='mt-3 password-subtitle'>
              <div>It appears that you are currently offline. Please check your internet connection.</div>
          </div>
        </div>
      </div>
    </div>
    );
  }
};

export default NoInternetConnection;
