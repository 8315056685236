import { AUTH_API_CONSTANTS } from '../../Constants/apiConstants';
import { ILoginForm, IRegisterForm } from '../../Entities/InputInterface';
import { ResetPasswordType, ChangePasswordType } from '../../Entities/userStoreTypes';
import { RequestService } from './requestServices';

export class AuthRequest extends RequestService {
  sendVerificationEmail = async () => {
    return this.get(AUTH_API_CONSTANTS.SEND_VERIFY_EMAIL);
  };

  userLogin = async (data: ILoginForm) => {
    return this.post(AUTH_API_CONSTANTS.LOGIN, data);
  };

  register = async (data: IRegisterForm) => {
    return this.post(AUTH_API_CONSTANTS.REGISTER, data);
  };

  logout = async () => {
    return this.post(AUTH_API_CONSTANTS.LOGOUT);
  };

  changePassword = async (data: ChangePasswordType) => {
    return this.patch(AUTH_API_CONSTANTS.CHANGE_PASSWORD, data);
  };

  resendOtp = (data: {phone_number: string, country_code: string}) => {
    return this.post(AUTH_API_CONSTANTS.RESEND_OTP, data);
  };

  otpVerification = (data: {otp: string}) => {
    return this.post(AUTH_API_CONSTANTS.OTP_VERIFICATION, data);
  };

  forgotPassword = async(data: ResetPasswordType) => {
    return this.post(AUTH_API_CONSTANTS.FORGOT_PASSWORD, data);
  };

  forgotPasswordToken = async(data: ResetPasswordType) => {
    return this.post(AUTH_API_CONSTANTS.FORGOT_PASSWORD_RESET_TOKEN, data);
  };

  resetPassword = async(data: ResetPasswordType) => {
    return this.post(AUTH_API_CONSTANTS.PASSWORD_RESET, data);
  };

  changePhoneNumber = async(phoneNumber: {phone_number: string, country_code: string}) => {
    return this.post(AUTH_API_CONSTANTS.CHANGE_PHONE_NUMBER, phoneNumber);
  };
}
