export const filterData = [
    {
        title: 'Ongoing',
        value: 'Ongoing',
        id: '1',
        checked: false
    },
    {
        title: 'Completed',
        value: 'Completed',
        id: '2',
        checked: false
    },
    {
        title: 'Draft',
        value: 'Draft',
        id: '3',
        checked: false
    },
    {
        title: 'Canceled',
        value: 'Cancelled',
        id: '4',
        checked: false
    }
];

export const categoryData = [
    {
        title: 'Kitchen Remodelling',
        id: '1',
        checked: false
    },
    {
        title: 'Bathroom Remodelling',
        id: '2',
        checked: false
    },
    {
        title: 'New Flooring',
        id: '3',
        checked: false
    },
    {
        title: 'Home Maintainence',
        id: '4',
        checked: false
    },
];

export const reissueCardReason = [
    {
        id: '1',
        title: 'My card was stolen',
        reason: 'stolen'
    },
    {
        id: '2',
        title: 'My card was lost',
        reason: 'lost'
    },
    {
        id: '3',
        title: 'My card was damaged',
        reason: 'damaged'
    }
];
