import { ESTIMATES_TYPES, MODEL_TYPE } from '../../Constants/constants';
import { getCookie, setCookie } from './cookiesServices';

// ICONS
import kitchenIcon from '../../UI/Assets/Images/cat-kitchen.svg';
import bathroomIcon from '../../UI/Assets/Images/cat-bathroom.svg';
import floorIcon from '../../UI/Assets/Images/cat-floor.svg';
import generalIcon from '../../UI/Assets/Images/cat-general.svg';
import { IAddAddressTypes } from '../../Entities/propertyTypes';
import { defaultData } from '../Helpers/commonHelpers';
import { IMailingAddressTypes } from '../../Entities/mailingAddressTypes';
import { NavigateFunction } from 'react-router-dom';
import { AUTHPATHS } from '../../Routes/Config/paths';
import { PROJECT_MODEL_TYPE } from '../../Constants/dropDownConstants';

// Passing Payload Data
export const addPropertyPayload = (values: IAddAddressTypes) => {
  return {
    address_line_1: values.address_line_1,
    address_line_2: values.address_line_2,
    unit_number:  defaultData(values.unit_number),
    state: values.state,
    city: values.city,
    zestimate_range: defaultData(values.zestimate_range),
    home_value: values.home_value,
    total_square_feet: values.total_square_feet,
    zestimate_change: defaultData(values.zestimate_change),
    property_type: values.property_type,
    living_square_feet: values.living_square_feet,
    bathroom_count: defaultData(values.bathroom_count),
    half_bathroom: defaultData(values.half_bathroom ),
    bedroom_count: defaultData(values.bedroom_count),
    year_built: defaultData(values.year_built),
    lot_size: defaultData(values.lot_size),
    foundation: values.foundation,
    roof: values.roof,
    heating: values.heating,
    total_stories: defaultData(values.total_stories),
    parking: defaultData(values.parking),
    construction_material: values.construction_material,
    cooling: values.cooling,
  };
};

// Adding Icons in the Property Remodelling type.
export const getModelTypeIcon = (title: string | undefined) => {
  let modelIcon: string | undefined;
  switch (title) {
    case PROJECT_MODEL_TYPE.KITCHEN:
      modelIcon = kitchenIcon;
      break;
    case PROJECT_MODEL_TYPE.BATHROOM:
      modelIcon = bathroomIcon;
      break;
    case PROJECT_MODEL_TYPE.FLOOR:
      modelIcon = floorIcon;
      break;
    case PROJECT_MODEL_TYPE.GENERAL:
      modelIcon = generalIcon;
      break;
  }
  return modelIcon;
};

// Get active class for selected card.
export const getActiveCard = (values: Array<string> | undefined, id: number) => {
  let selectedCardClass =  'category-card';

  values && values.forEach((element: string) => {
    if(element === String(id)){
      selectedCardClass =  `${selectedCardClass} selected`;
    }
    return selectedCardClass;
  });

  return selectedCardClass ;
};

// Project -> Cost Estimate Data Manipulation.
export const getEstimateData = (title: string, data: any) => {
  let typeData = '';
  data && data.forEach((element: any )=> {
    if(element?.title === title) {
      typeData = element;
    }
  });
  return typeData;
};

// SetEstimate Data.
export const setEstimateValue = (title: string, setFieldValue: any, id: number, estimateData: string) => {
  let estimate = {};

  switch (title) {
    case ESTIMATES_TYPES.KITCHEN_REMODEL:
      estimate = {
        project_type_id: '1',
        estimate_id: id,
        estimated_cost: estimateData,
      };
      setFieldValue('kitchenRemodel', estimate);
      break;
    case ESTIMATES_TYPES.BATHROOM_REMODEL:
      estimate = {
        project_type_id: '2',
        estimate_id: id,
        estimated_cost: estimateData,
      };
      setFieldValue('bathroomRemodel', estimate);
      break;
    case ESTIMATES_TYPES.FLOOR_REPLACEMENT:
      estimate = {
        project_type_id: '3',
        estimate_id: id,
        estimated_cost: estimateData,
      };
      setFieldValue('floorReplacement', estimate);
      break;
    case ESTIMATES_TYPES.GENERAL_MAINTENANCE:
      estimate = {
        project_type_id: '4',
        estimate_id: id,
        estimated_cost: estimateData,
      };
      setFieldValue('generalMaintenance', estimate);
      break;
  }
};

export const setInputName = (title: string) => {
  let name= '';
  switch (title) {
    case 'Kitchen Remodel':
      name = 'kitchenRemodel';
      break;
    case 'Bathroom Remodel':
      name = 'bathroomRemodel';
      break;
    case 'Floor Replacement':
      name = 'floorReplacement';
      break;
    case 'General Maintenance':
     name = 'generalMaintenance';
      break;
  };
  return name;
};

export const getHighEndValue = (title: string, highend: any, selected: string) => {
  let value = '';
  switch (title) {
    case 'Kitchen Remodel':
    case 'Bathroom Remodel':
      value = highend?.estimated_cost;
      break;
    case 'Floor Replacement':
      value = selected || highend?.children?.recommended?.estimated_cost;
      break;
    case 'General Maintenance':
     value = highend?.maintenance?.estimated_cost;
      break;
  };
  return value;
};

// Payload for estimates.
export const estimatePayload = (values: any, projectId: string) => {
  const totalEstimatedCost =
    Number(values?.bathroomRemodel?.estimated_cost) +
    Number(values?.kitchenRemodel?.estimated_cost) +
    Number(values?.floorReplacement?.estimated_cost) +
    Number(values?.generalMaintenance?.estimated_cost);
  const newArray = [];

  if (values?.bathroomRemodel?.estimate_id) {
    newArray.push(values?.bathroomRemodel);
  }
  if (values?.kitchenRemodel?.estimate_id) {
    newArray.push(values?.kitchenRemodel);
  }
  if (values?.floorReplacement?.estimate_id) {
    newArray.push(values?.floorReplacement);
  }
  if (values?.generalMaintenance?.estimate_id) {
    newArray.push(values?.generalMaintenance);
  }

  return {
    estimate_cost: totalEstimatedCost,
    project_id: projectId,
    remodelling: newArray,
  };
};

export const mailingAddressPayload = (values: IMailingAddressTypes) => {
  return {
    address_line_1: values.address_line_1,
    address_line_2: values.address_line_2,
    state: values.state,
    city: values.city,
    postal_code: values.postal_code,
  };
};

// Next step fuction for plaid bank response.
export const bankResponseToken = (res: any, setPlaidModal: (val: boolean) => void) => {
  if(res) {
    setPlaidModal(true);
    setCookie('linkToken',res?.link_token);
  }
};

// Function for checking the condition for minimum bank balance and navigation.
export const navigateBankUrl = (balance: boolean, navigate: NavigateFunction) => {
  if(balance) {
    navigate(AUTHPATHS.ACCOUNT_CREATE);
  } else {
    navigate(AUTHPATHS.ACCOUNT_NOT_ELIGIBLE);
  }
};
