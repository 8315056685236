import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { project } from '../../../../Constants/reducerEnums';

const initialState: any = {
  category: [],
  status: []
};

// Reducer for Project data.
export const projectFilterSlice = createSlice({
  name: project.projectFilter,
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<{}>) => {
      state.status = action.payload; // Set status filter data.
    },
    setCategory: (state, action: PayloadAction<{}>) => {
      state.category = action.payload; // Set category filter data.
    },
    resetFilter: (state) => { // Reseting filter.
        state.category= [];
        state.status = [];
    }
  },
});

export const { setStatus, setCategory, resetFilter } = projectFilterSlice.actions;
export default projectFilterSlice.reducer;
