import AppRoutes from './Routes/Wrappers/appRoutes';
import NoInternetConnection from './UI/Components/Dashboard/noInternetConnection';

const App = () => {
  return (
    <>
      <NoInternetConnection>
        <AppRoutes />
      </NoInternetConnection>
    </>
  );
};

export default App;
