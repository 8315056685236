export const loginInitialValues = {
  phone_number: '',
  password: '',
  country_code: '',
};

export const registerInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  password: '',
  confirm_password: '',
  country_code: '',
};

export const forgotPasswordInitialValue = {
  phone_number: '',
  country_code: '',
};

export const addAddressInitialValue = {
  address_line_1: '',
  address_line_2: '',
  unit_number: '',
  state: '',
  city: '',
  zestimate_range: '',
  home_value: '',
  total_square_feet: '',
  zestimate_change: '',
  property_type: '',
  living_square_feet: '',
  bathroom_count: '',
  half_bathroom: '',
  bedroom_count: '',
  year_built: '',
  lot_size: '',
  foundation: '',
  roof: '',
  heating: '',
  total_stories: '',
  parking: '',
  construction_material: '',
  cooling: '',
};

export const createPorjectName = {
  projectType: [],
};

export const createAccountInitialValues = {
    first_name :'',
    last_name: '',
    dob: '',
    ssn: '',
    phone_number: '',
    email: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    postal_code: '',
  };

export const savingPlanInitialValue = {
  customCost : ''
};

export const costEstimationInitialValue = {
    kitchenRemodel: {
      project_type_id: '',
      estimate_id: '',
      estimated_cost: '',
    },
    bathroomRemodel: {
      project_type_id: '',
      estimate_id: '',
      estimated_cost: '',
    },
    floorReplacement: {
      project_type_id: '',
      estimate_id: '',
      estimated_cost: '',
    },
    generalMaintenance: {
      project_type_id: '',
      estimate_id: '',
      estimated_cost: '',
    },
};

// Estimate Initial Values.
export const estimateInitialValues = {
  estimate_id: '',
  estimated_cost: '',
  project_type_id: ''
};

export const mailingAddressInitialValues = {
  postal_code: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
};

export const cardReasonInitialValues = {
  lock_reason: '',
};

export const updateProfileInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

export const changePasswordInitialValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
};

export const resetPasswordInitialValues = {
  password: '',
  confirmPassword: ''
};

export const forgotPasswordValues = {
  phoneNumber: '',
  countryCode: ''
};

export const confirmPasswordInitialValue = {
  validatePassword: ''
};
