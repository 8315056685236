// Success Toast Messages
// Key start with EMI is for tenure -> updated due to client feedback.
export const SUCCESS_TOAST = {
  VERIFY_EMAIL_SEND: 'Verification email has been sent successfully.',
  SUCCESS_BANK_CHANGE:
    'Your new account has been linked to your Saivy saving account.',
  TRANSACTION_SUCCESS: 'Amount has been transfered successfully.',
  TENURE_UPDATE: 'Project has been created.',
  EMI_TENURE_CANCEL: 'Recurring payment has been cancelled successfully.',
  ACCOUNT_ACTIVE: 'Your savings account is active now.',
  MAILING_ADDRESS_SUCCESS: 'Your Address has been saved successfully.',
  EMI_TENURE_PAUSE: 'Recurring payment has been paused successfully.',
  EMI_TENURE_SKIP: 'Recurring payment has been skipped successfully.',
  EMI_TENURE_RESUME: 'Recurring payment has been resumed successfully.',
  PROFILE_UPDATED: 'Your profile has been updated.',
  RESEND_OTP: 'OTP has been sent successfully.',
  PASSWORD_UPDATED: 'Password changed successfully.',
  REISSUE_CARD: 'Card reissue request has been submitted successfully.',
  RESET_PASSWORD: 'Your password has been updated.',
  PHONE_CHANGE: 'Phone number changed successfully.',
};

export const VALIDATION_MESSAGES = {
  PASSWORD: 'Invalid!',
  REQUIRED: 'Required!',
  INVALID: 'Invalid!',
  VALIDATION_PASSWORD: `Password doesn’t match!`,
  YEAR_INVALID: 'Year must be current year or less than current year',
  TRY_AGAIN: 'Please Login and Try again',
  TITLE_REQUIRED: 'Please enter the project title',
  PROJECT_REQUIRED: 'Please select a project type',
  MAX_WORDS_ALLOWED: 'Maximum 100 characters are allowed',
  PARKING: 'Parking is must be number',
  MINIMUM_SAVING_ALLOWED: 'Please enter a valid amount to proceed.',
  EIGHTEEN_YEAR: 567648000000,
  AGE_VALIDATION_MESSAGE: 'Your Age must be at least 18 years Old',
};

export const ERROR_MESSAGES = {
  INTERNAL_SERVER_ERROR: 'Internal server error.',
  CAPTCHA_REQUIRED: 'Captcha verification is required.',
  GOOGLE_PLACE_SELECTED: 'Please select address from the autosuggestions.',
  ISSUE_SELECT: 'At least one reason should be selected.',
  BUDGET_SELECT: 'Please select a budget to proceed.',
  ALL_FIELDS_ARE_MANDATORY : 'Accept All Terms And Conditions ! To Proceed KYC.',
  FIELDS_REQUIRED: 'Some fields are required.'
};
