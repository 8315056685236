import { Modal } from 'react-bootstrap';

const ConfirmAddressModal = (props: any) => {
  const { show, onShow,onHide,heading,children } = props;
  return (
    <Modal
      show={show}
      onHide={onHide}
      onShow={onShow}
      backdrop='static'
      keyboard={false}
      centered
      dialogClassName='modal-xs'
    >
      <Modal.Body>
        <div className='modal-title'>{heading}</div>
        <div className='number-form'>{children}</div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmAddressModal;

