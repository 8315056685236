import React from 'react';
import { toast } from 'react-toastify';

const toastSettings: any = {
  position: 'bottom-center',
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  pauseOnFocusLoss: false,
  theme: 'colored',
  maxOpened: 2,
};

export const successToast = (message: string) => {
  toast.success(message, toastSettings);
};

export const errorToast = (message: string) => {
  toast.error(message, toastSettings);
};
