import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_CONSTANTS } from '../../../../Constants/apiConstants';
import { profileEnums } from '../../../../Constants/reducerEnums';
import { GetRequest } from '../../../ApiServices/request';

const initialState: any = {
    isLoading: false,
    profile: null,
    isError: false,
    isSuccess: false,
    message: null,
};

export const getUserProfile = createAsyncThunk(
    profileEnums.getProfile,
    async (_userData, thunkAPI) => {
        try {
            const { data, status } = await GetRequest(
                API_CONSTANTS.USER_PROFILE
            );
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const userSlice = createSlice({
    name: profileEnums.userProfile,
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserProfile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUserProfile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profile = action.payload;
            })
            .addCase(getUserProfile.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            });
    },
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;
