import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import store from './DataSources/Redux/Store/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './UI/Assets/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-intl-tel-input/dist/main.css';
import 'rc-slider/assets/index.css';
import { ToastContainer } from 'react-toastify';
import ToastButton from './UI/Components/Shared/Toastify/toastButton';
import { TOASTER_AUTO_CLOSE_TIMESTAMP, TOAST_LIMIT } from './Constants/constants';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ToastContainer closeButton={ToastButton} autoClose = {TOASTER_AUTO_CLOSE_TIMESTAMP} limit = {TOAST_LIMIT} />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
