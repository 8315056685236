export const COMMONPATHS = {
  LOGIN: '/login',
  REGISTER: '/register',
  DEFAULT: '/',
  html: '/html',
  APP: '/*',
  FORGOT_PASSWORD: '/forgot-password',
  PRIVACY_POLICIES: '/privacy-policies',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  VERIFY_OTP: '/verify-otp',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASSWORD: '/reset-password'
};

export const AUTHPATHS = {
  CALLBACK: '*',
  DEFAULT: '/:app/home',
  DASHBOARD: '/dashboard',
  ADD_PROPERTY: '/add-property',
  SEARCH_LOCATION: '/search-location',
  ADD_PROJECT: '/add-project-name',
  COST_ESTIMATES: '/cost-estimates',
  PRIVACY_PLOICIES: '/privacy-policies',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  ACCOUNT_CREATE:'/create-account',
  ACCOUNT_ELIGIBILITY: '/account-eligible',
  RISE_DISCLOSURE: '/rize-disclosure',
  PROJECT_DETAILS: '/project-details',
  KYC_PROCESS:'/kyc-process',
  PAYMENT_TYPE: '/payment-type',
  CUSTOM_SAVING: '/custom-saving',
  ACCOUNT_NOT_ELIGIBLE: '/account-not-eligible',
  TENURE_SELECTION: '/tenure-selection',
  ONE_TIME_DEPOSITE: '/one-time-deposite',
  MAILING_ADDRESS: '/mailing-address',
};
