import React from 'react';
import { Modal } from 'react-bootstrap';
import { IModalPropsTypes } from '../../../../Entities/propsTypes';
import crossIcon from '../../../Assets/Images/close-header.svg';

const CommonModal = (props: IModalPropsTypes) => {
  const {
    show,
    onShow,
    children,
    onHide,
    dialogueClassName,
    centered,
    heading,
    headingClass,
    hideHeading,
    transactionModal,
    onModalClose
  } = props;
  return (
    <Modal
      dialogClassName={`${
        dialogueClassName ? dialogueClassName : 'modal-right'
      }`}
      show={show}
      onShow={onShow}
      onHide={onHide}
      backdrop='static'
      keyboard={false}
      centered={centered ? true : false}
    >
      <Modal.Body>
        <>
          {transactionModal && (
            <>
              <div className='modal-close' onClick={onHide}>
                <img src={crossIcon} alt='close' />
              </div>
              {hideHeading && (
                <div className={`profile-title text-${headingClass}`}>
                  {heading}
                </div>
              )}
            </>
          )}
        </>
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default CommonModal;
