import { createSlice } from '@reduxjs/toolkit';

export interface LoaderInterface {
  isMainLoading: boolean;
};

const initialState: LoaderInterface = {
  isMainLoading: true, // Inital loader state.
};

const loaderSlice = createSlice({
  name: 'saivy/loader',
  initialState,
  reducers: {
    startLoader: (state) => {
      state.isMainLoading = true; // Loader start when hit api.
    },
    stopLoader: (state) => {
      state.isMainLoading = false; // After Api response loader stopped.
    },
  },
});

// These Action creators are generated for each function while loader start or stop.
export const { startLoader, stopLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
