import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_CONSTANTS } from '../../../../Constants/apiConstants';
import { zillowProperty } from '../../../../Constants/reducerEnums';
import { IPropertyStoreType } from '../../../../Entities/authStoreTypes';
import { successToast } from '../../../../UI/Components/Shared/Toastify/toaster';
import { PostRequest } from '../../../ApiServices/request';

const initialState: IPropertyStoreType = {
    isLoading: false,
    zillow: null,
    isError: false,
    isSuccess: false,
    message: null,
};

// Function for integrate the API for zillow ( For fetching the property details ).
export const getPropertyDetails = createAsyncThunk(
    zillowProperty.getZillowData,
    async (address: {}, thunkAPI) => {
        try {
            const { data, status } = await PostRequest(API_CONSTANTS.EXTERNAL_PROPERTY_DETAILS, address);
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);


export const zillowSlice = createSlice({
    name: zillowProperty.zillowProperty,
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPropertyDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPropertyDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.zillow = action.payload;
            })
            .addCase(getPropertyDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.zillow = null;
            });
    },
});

export const { reset } = zillowSlice.actions;
export default zillowSlice.reducer;

