import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_CONSTANTS } from '../../../../Constants/apiConstants';
import { bankDetails } from '../../../../Constants/reducerEnums';
import { IBankDetails} from '../../../../Entities/projectTypes';
import { successToast } from '../../../../UI/Components/Shared/Toastify/toaster';
import { GetRequest, PostRequest } from '../../../ApiServices/request';

const initialState: IBankDetails = {
    isLoading: false,
    bank: null,
    isError: false,
    isSuccess: false,
    message: null,
    riseDocs:null
};

// Bank Model type.
export const postCreateAccountDetails = createAsyncThunk(
    bankDetails.createAccount,
    async (accountData: {}, thunkAPI) => {
        try {
            const { data, status } = await PostRequest(
                API_CONSTANTS.CREATE_ACCOUNT, accountData
            );
            successToast(data?.message);
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getRiseDisclosure = createAsyncThunk(
    bankDetails.riseDisclosure,
    async (_bankData, thunkAPI) => {
        try {
            const { data, status } = await GetRequest(
                API_CONSTANTS.RISE_DISCLOSURE
            );
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const postAcknowledgedData = createAsyncThunk(
    bankDetails.acknowledge,
    async (acknowledgeData: {}, thunkAPI) => {
        try {
            const { data, status } = await PostRequest(
                API_CONSTANTS.RISE_DISCLOSURE_ACKNOWLEDGE, acknowledgeData
            );
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const postKycDocuments = createAsyncThunk(
    bankDetails.kycDocumentation,
    async (kycDocuments: {}, thunkAPI) => {
        try {
            const { data, status } = await PostRequest(
                API_CONSTANTS.CREATE_ACCOUNT, kycDocuments
            );
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const bankSlice = createSlice({
    name: bankDetails.createAccount,
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postCreateAccountDetails.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(postCreateAccountDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.bank = action.payload;
            })
            .addCase(postCreateAccountDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.bank = null;
            })
            .addCase(getRiseDisclosure.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRiseDisclosure.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.riseDocs= action.payload;
            })
            .addCase(getRiseDisclosure.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.riseDocs = null;
            })
            .addCase(postAcknowledgedData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(postAcknowledgedData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.riseDocs = action.payload;
            })
            .addCase(postAcknowledgedData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.riseDocs = null;
            })
            .addCase(postKycDocuments.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(postKycDocuments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.bank = action.payload;
            })
            .addCase(postKycDocuments.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.bank = null;
            });

    },
});

export const { reset } = bankSlice.actions;
export default bankSlice.reducer;
