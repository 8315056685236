import React from 'react';

const OpenSaivyBankAccount = (props:any) => {
    
  return (
    <>
      <div className='profile-links-container'>
        <div className='profile-link-row'>
          <div
            className='profile-link-btn'
            onClick={props.onClick}
          >
            Open Saivy Bank Account
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenSaivyBankAccount;
