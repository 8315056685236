import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import homeInactive from '../../../Assets/Images/home-inactive.svg';
import deleteIcon from '../../../Assets/Images/delete-prop.svg';
import homeActive from '../../../Assets/Images/home-active.svg';
import { useWebSelector } from '../../../../DataSources/Redux/Store/hooks';
import { IPropertListing } from '../../../../Entities/propsTypes';
import {
  getCookie,
  setCookie,
} from '../../../../Container/Services/cookiesServices';
import { CAROUSEL_SETTINGS } from '../../../../Constants/constants';
import { useNavigate } from 'react-router-dom';
import { AUTHPATHS } from '../../../../Routes/Config/paths';
import { ProjectRequest } from '../../../../Container/Services/projectServices';
import { useDispatch } from 'react-redux';
import { setProjectList } from '../../../../DataSources/Redux/Slices/Project/projectListing';
import { PropertyServices } from '../../../../Container/Services/propertyServices';
import { setPropertyData, setSeletedProperty } from '../../../../DataSources/Redux/Slices/Property/propertyDataSlice';
import MainLoader from '../../Shared/Loader/mainLoader';
import { resetFilter } from '../../../../DataSources/Redux/Slices/Project/projectFilterSlice';

const PorpertyListing = (props: IPropertListing) => {
  const { hidden, setSelectedProperty, setHide } = props;
  const { property } = useWebSelector((propertyState) => propertyState);
  const propertyData = property.property?.data?.data;
  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activePropertyId = getCookie('activeProperty');
  const { activeProperty } = useWebSelector((selected) => selected.propertyData);

  const getListings = () => {
    setLoading(true);
    dispatch(resetFilter());
    PropertyServices.getPropertyDetails(activePropertyId)
      .then((response) => {
        if (response) {
          dispatch(setPropertyData(response));
        }
        ProjectRequest.getProjectListing(activePropertyId).then((res) => {
          if (res) {
            dispatch(setProjectList(res));
          }
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setHide(true);
  }, []);

  return (
    <>
      {isLoading && <MainLoader />}
      <div className="header-property-list-row" hidden={hidden}>
        <div className="container">
          <div className="property-label">Your properties are listed here.</div>
          <div className="property-row">
            <div
              className="add-property-btn"
              onClick={() =>
                navigate(AUTHPATHS.SEARCH_LOCATION, {
                  state: { newUser: false },
                })
              }
            >
              + New Property
            </div>

            <div className="property-list-row">
              <div className="selected-property-slider">
                <Slider {...CAROUSEL_SETTINGS}>
                  {/* div to be repeated */}
                  {propertyData?.length &&
                    propertyData?.map((data) => {
                      return (
                        <div
                          className={`property-card
                    ${
                      parseInt(activeProperty?.id || activePropertyId) === data?.id
                        ? 'selected-property'
                        : ''
                    }`}
                          key={data?.id}
                          onClick={() => {
                            setHide(true);
                            setSelectedProperty(data?.address as string);
                            setCookie('activeProperty', data?.id);
                            dispatch(setSeletedProperty({id: data?.id, address: data?.address}));
                            getListings();
                          }}
                        >
                          <div className="property-icon">
                            <img
                              src={
                                parseInt(getCookie('activeProperty')) ===
                                data?.id
                                  ? homeActive
                                  : homeInactive
                              }
                              alt="home"
                            />
                          </div>
                          <div className="property-name">{data?.address}</div>
                          <div className="prop-delete-btn">
                            <img
                              src={deleteIcon}
                              alt="delete"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                // TODO: Need to open the confirm modal for delete property.
                              }}
                            />
                          </div>
                          {parseInt(getCookie('activeProperty')) ===
                            data?.id && (
                            <div className="selected-label">
                              Selected Property
                            </div>
                          )}
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PorpertyListing;
