import { API_CONSTANTS } from '../../Constants/apiConstants';
import { getCookie } from '../../Container/Services/cookiesServices';
import {
  ICreateProject,
  ICustomEstimationType,
  IProjectListingType,
} from '../../Entities/projectTypes';
import { RequestService } from './requestServices';

export class ProjectRequests extends RequestService {

  customCostEstimation = async(data: ICustomEstimationType) => {
    return this.patch(API_CONSTANTS.CUSTOM_ESTIMATES, data);
  };

  // Project listing api resquest service.
  projectListing = async(data: IProjectListingType) => {
    const propertyId = getCookie('activeProperty');

    return this.get(
      `${API_CONSTANTS.PROJECT_LIST_PROPERTY}/${data?.id || propertyId}/project/list?page=${data?.page || 1}&status=${data?.status || ''}&category=${data?.category || ''}`
    );
  };

  // Create project request.
  addProject = async(data:ICreateProject) => {
    return this.post(API_CONSTANTS.CREATE_PROJECT, data);
  };

  // Add project estimate.
  addProjectEstimates = async(data: {}) => {
    return this.patch(API_CONSTANTS.COST_ESTIMATES, data);
  };

  // Getting custom cost segeregation.
  getCustomSegregation = async(projectId: string | undefined) => {
    return this.get(`${API_CONSTANTS.SEGREGATION_ESTIMATES}/${projectId}`);
  };

}
