import { CardRequest } from '../../DataSources/ApiServices/cardRequest';

export class CardServiceProvider {
  private static readonly cardRequest: CardRequest = new CardRequest();

  static getVirtualDebitCard = async () => {
    return this.cardRequest.getVirtualDebitCard();
  };
  static postPhysicalCardRequest = async () => {
    return this.cardRequest.postPhysicalCardRequest();
  };
  static getCardPinSetURL = async () => {
    return this.cardRequest.getCardPinSetURL();
  };
  static putLockAndUnlockCard = async (data: {activity: string, reason?: string}) => {
    const payload = {
        activity: data.activity,
        reason: { lock_reason: data.reason }
    };
    return this.cardRequest.putLockAndUnlockCard(payload);
  };

  static getDebitCardDetails = async () => {
    return this.cardRequest.getDebitCardDetail();
  };

  static reissueCardService = async (data: { reissueReason: string }) => {
    const payload = {
        reissue_reason: data.reissueReason
    };

    return this.cardRequest.reissueCard(payload);
  };
}
