import React from 'react';
import { Modal } from 'react-bootstrap';
import { IModalPropsTypes } from '../../../../Entities/propsTypes';
import crossIcon from '../../../Assets/Images/close-header.svg';

const SavingModal = (props: IModalPropsTypes) => {
    const { show, onHide, children, onCrossClick, hideCrossIcon } = props;
    return (
        <Modal size='lg' show={show} onHide={onHide} backdrop='static' keyboard={false} centered>
            <Modal.Body>
                <div hidden = {hideCrossIcon} className='modal-close' onClick={onCrossClick}>
                    <img src={crossIcon} alt='close' />
                </div>
                <div className='modal-project'>
                {children}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SavingModal;
