import React from 'react';
import propIcon from '../../Assets/Images/prop-icon.svg';
import popupIcon from '../../Assets/Images/home-prop.svg';
import { useWebSelector } from '../../../DataSources/Redux/Store/hooks';
import PropertyDetailProps from './propertyDetailProps';
import {
  getCurrencyData,
  getNullData,
  numberFormat,
  numberWithoutFormat,
} from '../../../Container/Helpers/propertyHelpers';

const PropertyDetails = () => {
  const { propertyData } = useWebSelector((property) => property.propertyData);

  return (
    <div className='property-detail-row'>
      <div className='property-detail-btn'>
        <img src={propIcon} alt='icon' />Property Details
      </div>
      <div className='property-detail-popup'>
        <div className='prop-title'>Property Details</div>
        <div className='prop-popup-icon'>
          <img src={popupIcon} alt='icon' />
        </div>
        {propertyData && (
          <>
            {/* Address column */}
            <div className='mb-4'>
              <PropertyDetailProps
                label='Address Line 1'
                value={getNullData(propertyData.address_line_1)}
              />
              <PropertyDetailProps
                label='Address Line 2'
                value={getNullData(propertyData.address_line_2)}
              />
              <PropertyDetailProps
                label='State'
                value={getNullData(propertyData.state)}
              />
              <PropertyDetailProps
                label='City'
                value={getNullData(propertyData.city)}
              />
              <PropertyDetailProps
                label='Unit No'
                value={numberWithoutFormat(propertyData.unit_number)}
              />
            </div>

            {/* Property estimates column */}
            <div className='mb-4'>
              <PropertyDetailProps
                label='Home Value'
                value={getCurrencyData(propertyData.home_value)}
              />
              <PropertyDetailProps
                label='Rent Estimates'
                value={getCurrencyData(propertyData.rent_estimate)}
              />
            </div>

            {/* Property area column */}
            <div className='mb-4'>
              <PropertyDetailProps
                label='Total square Footage'
                value={numberFormat(propertyData.total_square_feet)}
                unit={`${propertyData.total_square_feet ? 'sqft' : ''}`}
              />
              <PropertyDetailProps
                label='Square Footage'
                value={numberFormat(propertyData.living_square_feet)}
                unit={`${propertyData.living_square_feet ? 'sqft' : ''}`}
              />
              <PropertyDetailProps
                label='Lot Size'
                value={numberFormat(propertyData.lot_size)}
                unit={`${propertyData.lot_size ? 'sqft' : ''}`}
              />
            </div>

            {/* Property type column */}
            <div className='mb-4'>
              <PropertyDetailProps
                label='Property Type'
                value={getNullData(propertyData.property_type)}
              />
              <PropertyDetailProps
                label='Year Built'
                value={getNullData(propertyData.year_built)}
              />
              <PropertyDetailProps
                label='Stories'
                value={getNullData(propertyData.total_stories)}
              />
              <PropertyDetailProps
                label='Roof Type'
                value={getNullData(propertyData.roof)}
              />
              <PropertyDetailProps
                label='Full Bathrooms'
                value={getNullData(propertyData.bathroom_count)}
              />
              <PropertyDetailProps
                label='Half Bathrooms'
                value={getNullData(propertyData.half_bathroom)}
              />
              <PropertyDetailProps
                label='Bedrooms'
                value={getNullData(propertyData.bedroom_count)}
              />
            </div>

            {/* Foundation and material of the property */}
            <div className='mb-0'>
              <PropertyDetailProps
                label='Foundation'
                value={getNullData(propertyData.foundation)}
              />
              <PropertyDetailProps
                label='Construction Material'
                value={getNullData(propertyData.construction_material)}
              />
              <PropertyDetailProps
                label='Heating'
                value={getNullData(propertyData.heating)}
              />
              <PropertyDetailProps
                label='Cooling'
                value={getNullData(propertyData.cooling)}
              />
              <PropertyDetailProps
                label='Parking'
                value={getNullData(propertyData.parking)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PropertyDetails;
