import React, { useEffect, useState } from 'react';
import {
  NUMBERS,
  buttonName,
  headerButton,
  hideNavbar,
  BUTTON_COLOR,
  BACK_NAVIGATE,
} from '../../../../Constants/constants';
import logoIcon from '../../../Assets/Images/logo.svg';
import crossIcon from '../../../Assets/Images/close-header.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { getButtonLabel } from '../../../../Container/Helpers/commonHelpers';
import { AUTHPATHS, COMMONPATHS } from '../../../../Routes/Config/paths';
import { AuthServices } from '../../../../Container/Services/authServices';
import { deleteAllCookies, getCookie } from '../../../../Container/Services/cookiesServices';
import { useWebDispatch, useWebSelector } from '../../../../DataSources/Redux/Store/hooks';
import { getUserProfile } from '../../../../DataSources/Redux/Slices/User/userSlice';

const FormNavbar = () => {
  const [header, setHeader] = useState<boolean>(false);
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useWebDispatch();

  const { profile, isLoading } = useWebSelector((userState) => userState.profile);
  const buttonStatus = profile?.data?.user?.onboard_status === 0;

  useEffect(() => {
    if(getCookie('accessToken')) {
      dispatch(getUserProfile());
    }
  },[]);

  // This function is used for add class on header while scrollY.
  // When user starts scrolling, the setHeader state will be true and header background color will be changed.
  const setFixedNav = () => {
    if (window.scrollY >= NUMBERS.TWO) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  };
  window.addEventListener('scroll', setFixedNav);

  // Logout function for logging out from the platform.
  const logout = () => {
    AuthServices.logoutService().then(() => {
      localStorage.clear();
      deleteAllCookies();
      navigate(COMMONPATHS.LOGIN);
    });
  };

  // Navigate and changing the button label accordingly.
  const handleNavigate = () => {
    switch (pathname) {
      case COMMONPATHS.LOGIN:
      case COMMONPATHS.DEFAULT:
        navigate(COMMONPATHS.REGISTER);
        break;
      case COMMONPATHS.REGISTER:
        navigate(COMMONPATHS.LOGIN);
        break;
      case AUTHPATHS.SEARCH_LOCATION:
      case AUTHPATHS.ADD_PROPERTY:
        if(getButtonLabel(pathname, buttonStatus) === buttonName.cancel) {
          navigate(BACK_NAVIGATE);
        } else {
          logout();
        }
        break;
      case AUTHPATHS.ACCOUNT_CREATE:
        navigate(AUTHPATHS.DASHBOARD);
        break;
      default:
        break;
    }
  };

  // Remove hidden from when marketing pages will be available
  return (
    <div
      className={header ? 'main-header header-bg' : 'main-header'}
      hidden={hideNavbar.includes(pathname.split('/')[1])}
    >
      <div className='header-row'>
        <div className='container'>
          <div
            className='header-logo'
          >
            <img src={logoIcon} alt='logo' />
          </div>
          {/* Split path with '/' and checking that the resultant path is includes in headerButton array or not. */}
          {headerButton.includes(pathname.split('/')[1]) ? (
            <div className='header-cross'>
              <button
                hidden={isLoading}
                className={`btn ${
                  BUTTON_COLOR.includes(getButtonLabel(pathname, buttonStatus))
                    ? 'btn-logout'
                    : 'btn-login'
                }`}
                onClick={() => handleNavigate()}
              >
                {getButtonLabel(pathname, buttonStatus)}
              </button>
            </div>
          ) : (
            <div className='header-cross' onClick={() => {
              if(getCookie('access_token')) {
                navigate(AUTHPATHS.DASHBOARD);
              } else {
                navigate(COMMONPATHS.LOGIN);
              }
            }}
            >
              <img src={crossIcon} alt='logo' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormNavbar;
