import React, { FC, useEffect, useState } from 'react';
import { PhoneInputFieldTypes } from '../../../../Entities/InputInterface';
import IntlTelInput from 'react-intl-tel-input';

const PhoneInputField: FC<PhoneInputFieldTypes> = ({
    setPhonecode,
    setContactNumber,
    phoneNumberProp,
    countryCodeProp,
    onSelectFlag,
    disabled,
    defaultCountry
}) => {
    const [countryCode, setCountryCode] = useState(countryCodeProp || '1');
    const [phoneNumber, setPhoneNumber] = useState(phoneNumberProp || '');

    useEffect(() => {
        if (setPhonecode && setContactNumber) {
            setPhonecode(countryCode);
            setContactNumber(phoneNumber);
        }
    }, [phoneNumber, countryCode]);

    const getCountryCode = (number: any) => {
      return number.split(' ')?.[0];
    };

    return (
        <>
            <div className='phone-code'>
                <IntlTelInput
                    containerClassName='intl-tel-input'
                    format = {true}
                    defaultCountry={defaultCountry ? defaultCountry : 'us'}
                    onPhoneNumberChange={(
                        _isValid: boolean,
                        value: string,
                        _selectedCountryData: any,
                        _fullNumber: string
                    ) => {
                        setPhoneNumber(value);
                    }}
                    onSelectFlag={onSelectFlag}
                    disabled={disabled}
                />
            </div>
        </>
    );
};

export default PhoneInputField;
