import React from 'react';
import { Modal } from 'react-bootstrap';
import { IModalPropsTypes } from '../../../../Entities/propsTypes';

const FormModal = (props: IModalPropsTypes) => {
    const { show, onShow, heading, children } = props;
    return (
      <Modal
        className='modalfade'
        id='myModal'
        show={show}
        onShow={onShow}
        centered
        dialogClassName='mail-modal'
      >
        <Modal.Body>
          <div className='modal-title'>{heading}</div>
          <div className='number-form'>{children}</div>
        </Modal.Body>
      </Modal>
    );
};

export default FormModal;
