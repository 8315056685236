import { ProjectRequests } from '../../DataSources/ApiServices/projectRequest';
import { ICreateProject, ICustomEstimationType, IProjectListingType } from '../../Entities/projectTypes';
import { getCookie } from './cookiesServices';

export class ProjectRequest {
  private static readonly projectRequests: ProjectRequests = new ProjectRequests();

  // Custom cost estimation.
  static updateEstimation = async(data: ICustomEstimationType) => {

    const estimationPayload = {
        project_id : data?.projectId,
        custom_cost: parseFloat(String(data?.customCost))
    };

    return this.projectRequests.customCostEstimation(estimationPayload);

  };

  // Project listing.
  static getProjectListing = async(data: IProjectListingType) => {

    return this.projectRequests.projectListing(data);
  };

  // Create project.
  static addNewProject = async(data: ICreateProject) => {

    const payload = {
      property_id: getCookie('activeProperty'),
      project_type: data.projectType,
      dashboard_type: data.dashboardType
    };

    return this.projectRequests.addProject(payload);
  };

  // Add project estimates.
  static addEstimate = async(data: {}) => {
    return this.projectRequests.addProjectEstimates(data);
  };

  // Get segregation estimate data.
  static getSegregationEstimates = async(projectId: string | undefined) => {
    return this.projectRequests.getCustomSegregation(projectId);
  };

};
