import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { reissueCardReason } from '../../../../../../Constants/filterData';
import LoadingButton from '../../../Buttons/loadingButton';
import { errorToast, successToast } from '../../../Toastify/toaster';
import { CardServiceProvider } from '../../../../../../Container/Services/cardServices';
import { ERROR_MESSAGES, SUCCESS_TOAST } from '../../../../../../Constants/messages';
import backBtn from '../../../../../Assets/Images/profile-back.svg';

const CardReissueForm = (props: any) => {
  const { onModalClose } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [reissueReason, setReissueReason] = useState<string>('');

  return (
    <div className='modal-card'>
      <div className='profile-back'>
        <img src={backBtn} alt='back' onClick={() => onModalClose()} />
      </div>
      <div className='modal-title'>Reissue Card</div>
      <div className='modal-subtitle text-center'>Please select from the answers below</div>
      <div className='card-options-col'>
        {reissueCardReason.map(
          (value: { id: string; title: string; reason: string }) => {
            return (
              <div
                className={`card-option-row ${
                  reissueReason === value.reason ? 'selected' : ''
                }`}
                key={value.id}
              >
                <div className='card-text'>{value.title}</div>
                <div className='estimate-radio'>
                  <Form.Check
                    type='radio'
                    name='card-option'
                    id={value.id}
                    onChange={() => {
                      setReissueReason(value.reason);
                    }}
                    checked={reissueReason === value.reason}
                  />
                </div>
              </div>
            );
          }
        )}
        <div className='card-modal-btns'>
          <LoadingButton
            title='Save'
            isLoading={isLoading}
            icon={true}
            className='btn btn-primary w-100'
            onClick={() => {
              setLoading(true);
              if(reissueReason) {
                CardServiceProvider.reissueCardService({reissueReason}).then((res) => {
                  if(res) {
                    onModalClose();
                    successToast(SUCCESS_TOAST.REISSUE_CARD);
                  }
                }).finally(() => setLoading(false));
              } else {
                setLoading(false);
                errorToast(ERROR_MESSAGES.ISSUE_SELECT);
              }
            }}
          />
          <button
            className='btn btn-danger w-100 mt-3'
            onClick={() => onModalClose()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardReissueForm;
