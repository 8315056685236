import { TRANSACTIONS_API_CONSTANTS } from '../../Constants/apiConstants';
import { ITranscationTypes, PauseTransactionType, SkipTransactionType } from '../../Entities/bankTypes';
import { RequestService } from './requestServices';

export class TransactionRequest extends RequestService {

  // Create Transaction.
  createTranscation = async(data: ITranscationTypes) => {
    return this.post(TRANSACTIONS_API_CONSTANTS.ONE_TIME_DEPOSITE, data);
  };

  // Cancel Transaction.
  cancelTransaction = async(data: {transaction_id: string, password_confirm: string}) => {
    return this.patch(TRANSACTIONS_API_CONSTANTS.TRANSACTION_CANCEL, data);
  };

  // Pause Transaction.
  pauseTransaction = async(data: PauseTransactionType) => {
    return this.patch(TRANSACTIONS_API_CONSTANTS.TRANSACTION_PAUSE, data);
  };

  // Skip transaction.
  skipTransaction = async(data: SkipTransactionType) => {
    return this.patch(TRANSACTIONS_API_CONSTANTS.TRANSACTION_SKIP, data);
  };

  // Get transactions listing.
  getTransactions = async(page: number) => {
    return this.get(`${TRANSACTIONS_API_CONSTANTS.TRANSACTION_LISTING}?page=${page}`);
  };

  // Get transaction listing for particular project.
  getProjectTransaction = async(data: {project_id: string | undefined, page: number}) => {
    return this.get(`${TRANSACTIONS_API_CONSTANTS.PROJECT_TRANSACTION}/${data.project_id}/list?page=${data.page}`);
  };

};
