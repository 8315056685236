import { FormikInterface } from '../../../../Entities/InputInterface';
import { Input, Textarea, CardTextArea } from '../InputFields/Input';

const FormikController = (props: FormikInterface): JSX.Element | null => {
  const { control, ...rest } = props;
  switch (control) {
    case 'input':
      return <Input {...rest} />;
    case 'textArea':
      return <Textarea {...rest} />;
    case 'cardTextArea':
      return <CardTextArea {...rest} />;
    default:
      return null;
  }
};
export default FormikController;
