import React from 'react';
import { AuthServices } from '../../../../../Container/Services/authServices';
import { successToast } from '../../Toastify/toaster';
import errorIcon from '../../../../Assets/Images/error-icon.svg';
import droprightIcon from '../../../../Assets/Images/dropright-icon.svg';
import { useWebDispatch } from '../../../../../DataSources/Redux/Store/hooks';
import { getUserProfile } from '../../../../../DataSources/Redux/Slices/User/userSlice';
import { SUCCESS_TOAST } from '../../../../../Constants/messages';

const VerifyEmail = (props: { emailVerified: string | undefined; email: string | undefined }) => {
  const { emailVerified, email } = props;
  const dispatch = useWebDispatch();

  return (
    <div className='email-row'>
      <div className='user-email'>{email}</div>
      <div className='verify-email-col'>
        {!emailVerified && (
          <img src={errorIcon} className='error-icon' alt='icon' />
        )}
        <div
          className='verify-email-container'
          onClick={() => {
            AuthServices.verifyEmailSend().then(() => {
              successToast(SUCCESS_TOAST.VERIFY_EMAIL_SEND);
              dispatch(getUserProfile());
            });
          }}
        >
          <div className='verify-email-popup'>
            Verify Your Email
            <img src={droprightIcon} alt='icon' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
