import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { property } from '../../../../Constants/reducerEnums';

const initialState: any = {
  data: []
};

// Reducer for zillow data.
export const zillowDataSlice = createSlice({
  name: property.getZillowData,
  initialState,
  reducers: {
    setZillowData: (state, action: PayloadAction<{}>) => {
      state.data = action.payload; // State function for setting data in reducers.
    },
    resetZillowData: (state) => {
      state.data = [null]; // Reset zillow Data.
    },
  },
});

export const { setZillowData, resetZillowData } = zillowDataSlice.actions;
export default zillowDataSlice.reducer;
