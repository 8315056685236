import React, { useState, useEffect, useRef } from 'react';
import crossIcon from '../../../Assets/Images/close-header.svg';
import { IAccountProps } from '../../../../Entities/propsTypes';
import { ACTIVE_LINKS, BANK_ACCOUNT_STATUS, NUMBERS } from '../../../../Constants/constants';
import PhysicalCard from './MailingAddress/BankingCards/physicalCard';
import TransactionsListing from '../../Transcations/transactionListing';
import ProfileDetailsModal from '../Modals/profileDetailsModal';
import ListingPagination from '../Pagination/listingPagination';
import { useWebSelector } from '../../../../DataSources/Redux/Store/hooks';
import Logout from './Logout/logout';
import UpdateProfile from '../../User/updateProfile';
import ChangePassword from '../../User/changePassword';
import VerifyEmail from './AccountProps/verifyEmail';
import OpenBankAccount from './AccountProps/openBankAccount';
import { scrollToTop } from '../../../../Container/Helpers/commonHelpers';

const Account = (props: IAccountProps) => {
    const { close, profileData, setPlaidModal, active, setActive, setPage, page, open, setOpen } = props;
    const ref = useRef<any>(null);

    // Trasaction listing modal state.
    const [ showTransactions, setShowTransactions] = useState<boolean>(false); // Transaction Modal.
    const [ showCardDetails, setShowCardDetails ] = useState<boolean>(false); // Physical Card Request
    const [ profileUpdate, setProfileUpdate ] = useState<boolean>(false); // Update profile modal.
    const [ changePassword, setChangePassword ] = useState<boolean>(false); // Change password modal.

    const { transactions } = useWebSelector((transactionsList) => transactionsList.transactions);

    useEffect(() => {
        setActive('');
    },[]);

    return (
      <>
        <div className='modal-close' onClick={() => close()}>
          <img src={crossIcon} alt='close' />
        </div>
        <div className='profile-modal'>
          <div className='profile-head'>
            <div className='user-title'>
              {`${profileData?.first_name} ${profileData?.last_name}`}
            </div>
            <VerifyEmail emailVerified={profileData?.email_verified_at} email={profileData?.email}/>
            <div className='user-phone'>
              {`+${profileData?.country_code} ${profileData?.phone_number}`}
            </div>
            <button
              className='btn btn-primary profile-btn'
              onClick={() => {
                setProfileUpdate(!profileUpdate);
                setShowTransactions(false);
                setShowCardDetails(false);
                setChangePassword(false);
                setActive('');
                scrollToTop();
              }}
            >
              Edit Profile
            </button>
          </div>
          <OpenBankAccount
            isBankSync={profileData?.sync_account_status}
            isKycDone={profileData?.rize_kyc_status}
            isMinimumBalance={profileData?.is_minimum_balance}
            isRizeDisclouser = {profileData?.rize_disclosure_status}
            isRizeAccount={profileData?.rize_account_status}
            setShowTransactions={setShowTransactions}
            setChangePassword={setChangePassword}
            setActive={setActive}
            active={active}
            setPlaidModal={setPlaidModal}
            setProfileUpdate={setProfileUpdate}
            setShowCardDetails={setShowCardDetails}
            showCardDetails={showCardDetails}
          />
          <div className='profile-links-container'>
            <div className='profile-link-row' hidden={profileData?.sync_account_status === BANK_ACCOUNT_STATUS.NOT_CREATED}
            >
              <div
                className={`profile-link-btn ${(active === ACTIVE_LINKS.FOUR) && 'selected'}`}
                onClick={() => {
                  setShowTransactions(!showTransactions);
                  setShowCardDetails(false);
                  setProfileUpdate(false);
                  setChangePassword(false);
                  scrollToTop();
                  if (!showTransactions) {
                    setActive('4');
                  } else {
                    setActive('');
                  }
                }}
              >
                All Transactions
              </div>
            </div>
            <div className='profile-link-row'>
              <div className={`profile-link-btn ${
                  (active === ACTIVE_LINKS.FIVE) && 'selected'
                }`}
               onClick={() => {
                setChangePassword(!changePassword);
                setShowTransactions(false);
                setShowCardDetails(false);
                setProfileUpdate(false);
                scrollToTop();
                if (!changePassword) {
                  setActive('5');
                } else {
                  setActive('');
                }
               }}
              >Change Password</div>
            </div>
            <Logout />
          </div>
        </div>
        <PhysicalCard showCardDetails={showCardDetails} setOpen={setOpen} open={open} setShowCardDetails={setShowCardDetails}/>
        {showTransactions && (
          <ProfileDetailsModal
            heading='All Transactions'
            defaultHeading={transactions?.data.length > NUMBERS.ZERO ? true : false}
            headingClass='right-align'
            backNavigate={() => {
              setShowTransactions(false);
              setActive('');
            }}
          >
            <>
              <TransactionsListing setPage={setPage} page={page} transactions={transactions}/>
              {transactions?.total_page > NUMBERS.ONE && (
                <ListingPagination
                  setPage={props.setPage}
                  page={props.page}
                  totalPage={transactions?.total_page}
                />
              )}
            </>
          </ProfileDetailsModal>
        )}
        <UpdateProfile
          setActive={setActive}
          setProfileUpdate={setProfileUpdate}
          profileUpdate={profileUpdate}
          data={profileData}
        />
        <ChangePassword changePassword={changePassword} setChangePassword={setChangePassword} setActive={setActive}/>
      </>
    );
};

export default Account;
