import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MetaTags from '../../UI/Components/Shared/metaTags';
import { ScrollToTop } from '../../UI/Components/Shared/ScrollToTop';
import FormsLayout from '../Layouts/FormsLayout';
import PublicRoutes from '../publicRoutes';

const AppRoutes = () => {
  return (
    <Suspense>
      <Router>
      {/* {<MetaTags/>} */}
        {<ScrollToTop/>}
        <FormsLayout>
          <Routes>
            {PublicRoutes.map((route, key) => {
              return <Route key={key} {...route} />;
            })}
          </Routes>
        </FormsLayout>
      </Router>
    </Suspense>
  );
};

export default AppRoutes;
