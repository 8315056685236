import { DEFAULT_COUNTRY_CODE } from '../../Constants/constants';
import { AuthRequest } from '../../DataSources/ApiServices/authRequest';
import { IforgotPassword, ILoginForm, IRegisterForm } from '../../Entities/InputInterface';
import { ForgotPasswordType, ChangePasswordPayload } from '../../Entities/userStoreTypes';

export class AuthServices {
  private static readonly authRequest: AuthRequest = new AuthRequest();

  // Verify email trigger.
  static verifyEmailSend = async() => {
    return this.authRequest.sendVerificationEmail();
  };

  // User login.
  static userLoginService = async(data: ILoginForm) => {

    const loginPayload = {
      phone_number: data?.phone_number,
      password: data?.password,
      google_recaptcha_response: data?.google_recaptcha_response,
      country_code: data?.country_code || `+${DEFAULT_COUNTRY_CODE}`
    };

    return this.authRequest.userLogin(loginPayload);
  };

  // Register user.
  static userRegister = async(data: IRegisterForm) => {

    const registerPayload = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone_number: data?.phone_number,
      password: data?.password,
      confirm_password: data?.confirm_password,
      google_recaptcha_response: data?.google_recaptcha_response,
      country_code: data?.country_code || `+${DEFAULT_COUNTRY_CODE}`,
    };

    return this.authRequest.register(registerPayload);
  };

  // Logout service.
  static logoutService = () => {
    return this.authRequest.logout();
  };

  // Send otp.
  static resendOtpService = (data: {phoneNumber: string, countryCode: string}) => {
    const resendPayload = {
      phone_number: data.phoneNumber,
      country_code: data.countryCode || `+${DEFAULT_COUNTRY_CODE}`,
    };
    return this.authRequest.resendOtp(resendPayload);
  };

  static otpVerificationService = (data: {otp: string}) => {
    return this.authRequest.otpVerification(data);
  };

  // Forgot password.
  static forgotPasswordService = (data: ForgotPasswordType) => {
    const payload = {
      phone_number: data.phoneNumber,
      country_code: data.countryCode || `+${DEFAULT_COUNTRY_CODE}`,
    };

    return this.authRequest.forgotPassword(payload);
  };

  static forgotPassowrdTokenService = (data: ForgotPasswordType) => {
    const payload = {
      phone_number: data.phoneNumber,
      country_code: data.countryCode,
      otp: data.otp
    };

    return this.authRequest.forgotPasswordToken(payload);
  };

  static resetPasswordService = (data: ForgotPasswordType) => {
    const payload = {
      phone_number: data.phoneNumber,
      country_code: data.countryCode,
      password: data.password,
      confirm_password: data.confirmPassword,
      token: data.token
    };

    return this.authRequest.resetPassword(payload);
  };

  // Change Password.
  static changePasswordService = (data: ChangePasswordPayload) => {

    // Payload for change password.
    const payload = {
      current_password: data.currentPassword,
      new_password: data.newPassword,
      new_password_confirmation: data.confirmNewPassword
    };

    return this.authRequest.changePassword(payload);
  };

  static changePhoneNumberServices = async(data: IforgotPassword) => {
    const payload = {
      phone_number: data.phone_number,
      country_code: data.country_code || `+${DEFAULT_COUNTRY_CODE}`
    };
    return this.authRequest.changePhoneNumber(payload);
  };

};
