import * as Yup from 'yup';
import { VALIDATION_LENGTH, REGEX } from './constants';
import { VALIDATION_MESSAGES } from './messages';

export const loginSchema = Yup.object({
  phone_number: Yup.string()
    .min(VALIDATION_LENGTH.TEN, VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.THIRTEEN, VALIDATION_MESSAGES.INVALID)
    .matches(REGEX.PHONE_NUMBER, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED),
  password: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .matches(REGEX.PASSWORD, VALIDATION_MESSAGES.PASSWORD),
});

export const registerSchema = Yup.object({
  first_name: Yup.string()
    .min(VALIDATION_LENGTH.TWO, VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.FIFTY, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED)
    .matches(REGEX.ONLY_ALPHABETS, VALIDATION_MESSAGES.INVALID),
  last_name: Yup.string()
    .min(VALIDATION_LENGTH.TWO, VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.FIFTY, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED)
    .matches(REGEX.ONLY_ALPHABETS, VALIDATION_MESSAGES.INVALID),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.ONE_FIFTY, VALIDATION_MESSAGES.INVALID)
    .matches(REGEX.EMAIL, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED),
  phone_number: Yup.string()
    .min(VALIDATION_LENGTH.TEN, VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.THIRTEEN, VALIDATION_MESSAGES.INVALID)
    .matches(REGEX.NUMBERS, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED),
  password: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(VALIDATION_LENGTH.THIRTY, VALIDATION_MESSAGES.PASSWORD)
    .matches(REGEX.PASSWORD, VALIDATION_MESSAGES.PASSWORD),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], VALIDATION_MESSAGES.VALIDATION_PASSWORD)
    .required(VALIDATION_MESSAGES.REQUIRED),
});

export const forgotPasswordSchema = Yup.object({
  phone_number: Yup.string()
    .min(VALIDATION_LENGTH.TEN, VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.THIRTEEN, VALIDATION_MESSAGES.INVALID)
    .matches(REGEX.NUMBERS, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED),
});

export const propertyValidationSchema = Yup.object({
  address_line_1: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  address_line_2: Yup.string(),
  unit_number: Yup.number(),
  state: Yup.string(),
  city: Yup.string(),
  zestimate_range: Yup.number(),
  home_value: Yup.number()
    .min(VALIDATION_LENGTH.ZERO, VALIDATION_MESSAGES.INVALID)
    .integer()
    .required(VALIDATION_MESSAGES.REQUIRED),
  total_square_feet: Yup.number()
    .min(VALIDATION_LENGTH.ZERO, VALIDATION_MESSAGES.INVALID),
  zestimate_change: Yup.number(),
  property_type: Yup.string(),
  living_square_feet: Yup.number()
    .min(VALIDATION_LENGTH.ZERO, VALIDATION_MESSAGES.INVALID)
    .integer()
    .required(VALIDATION_MESSAGES.REQUIRED),
  bathroom_count: Yup.number().min(VALIDATION_LENGTH.ZERO, VALIDATION_MESSAGES.INVALID).integer(),
  bedroom_count: Yup.number().min(VALIDATION_LENGTH.ZERO, VALIDATION_MESSAGES.INVALID).integer(),
  year_built: Yup.date().max(
    new Date().getFullYear(),
    VALIDATION_MESSAGES.YEAR_INVALID
  ),
  lot_size: Yup.number().min(VALIDATION_LENGTH.ZERO, VALIDATION_MESSAGES.INVALID),
  foundation: Yup.string(),
  roof: Yup.string(),
  heating: Yup.string(),
  total_stories: Yup.number().min(VALIDATION_LENGTH.ZERO, VALIDATION_MESSAGES.INVALID).integer(),
  parking: Yup.string(),
  construction_material: Yup.string(),
  cooling: Yup.string(),
});

export const addProjectSchema = Yup.object({
  projectType: Yup.array().of(Yup.string()).min(VALIDATION_LENGTH.ONE),
});

export const addSavingPlanSchema = Yup.object({
  customCost: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
});

export const createAccountValidation = Yup.object({
  first_name: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  last_name: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  dob: Yup.date()
  .required(VALIDATION_MESSAGES.REQUIRED),
  ssn: Yup.string()
  .min(VALIDATION_LENGTH.ELEVEN, VALIDATION_MESSAGES.INVALID)
  .max(VALIDATION_LENGTH.ELEVEN, VALIDATION_MESSAGES.INVALID)
  .required(VALIDATION_MESSAGES.REQUIRED),
  phone_number: Yup.string()
  .min(VALIDATION_LENGTH.TEN, VALIDATION_MESSAGES.INVALID)
  .max(VALIDATION_LENGTH.THIRTEEN, VALIDATION_MESSAGES.INVALID)
  .matches(REGEX.NUMBERS, VALIDATION_MESSAGES.INVALID)
  .required(VALIDATION_MESSAGES.REQUIRED),
  email: Yup.string()
  .email(VALIDATION_MESSAGES.INVALID)
  .max(VALIDATION_LENGTH.ONE_FIFTY, VALIDATION_MESSAGES.INVALID)
  .matches(REGEX.EMAIL, VALIDATION_MESSAGES.INVALID)
  .required(VALIDATION_MESSAGES.REQUIRED),
  street1: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  street2: Yup.string(),
  city: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  state: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  postal_code: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).required(VALIDATION_MESSAGES.REQUIRED),
});

export const mailingAddressValidations = Yup.object({
  postal_code: Yup.number().positive().required(VALIDATION_MESSAGES.REQUIRED),
  address_line_1: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  address_line_2: Yup.string(),
  city: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
  state: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
});

export const cardLockUnlockValidations = Yup.object({
  lock_reason: Yup.string().required(VALIDATION_MESSAGES.REQUIRED)
});

export const updateProfileSchema = Yup.object({
  firstName: Yup.string()
    .min(VALIDATION_LENGTH.TWO, VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.FIFTY, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED)
    .matches(REGEX.ONLY_ALPHABETS, VALIDATION_MESSAGES.INVALID),
  lastName: Yup.string()
    .min(VALIDATION_LENGTH.TWO, VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.FIFTY, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED)
    .matches(REGEX.ONLY_ALPHABETS, VALIDATION_MESSAGES.INVALID),
  email: Yup.string()
    .email(VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.ONE_FIFTY, VALIDATION_MESSAGES.INVALID)
    .matches(REGEX.EMAIL, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED),
});

export const changePasswordSchema = Yup.object({
  currentPassword: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(VALIDATION_LENGTH.THIRTY, VALIDATION_MESSAGES.PASSWORD)
    .matches(REGEX.PASSWORD, VALIDATION_MESSAGES.PASSWORD),
  newPassword: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(VALIDATION_LENGTH.THIRTY, VALIDATION_MESSAGES.PASSWORD)
    .matches(REGEX.PASSWORD, VALIDATION_MESSAGES.PASSWORD),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], VALIDATION_MESSAGES.VALIDATION_PASSWORD)
    .required(VALIDATION_MESSAGES.REQUIRED),
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required(VALIDATION_MESSAGES.REQUIRED)
    .max(VALIDATION_LENGTH.THIRTY, VALIDATION_MESSAGES.PASSWORD)
    .matches(REGEX.PASSWORD, VALIDATION_MESSAGES.PASSWORD),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], VALIDATION_MESSAGES.VALIDATION_PASSWORD)
    .required(VALIDATION_MESSAGES.REQUIRED),
});

export const forgotPassword = Yup.object({
  phoneNumber: Yup.string()
    .min(VALIDATION_LENGTH.TEN, VALIDATION_MESSAGES.INVALID)
    .max(VALIDATION_LENGTH.THIRTEEN, VALIDATION_MESSAGES.INVALID)
    .matches(REGEX.NUMBERS, VALIDATION_MESSAGES.INVALID)
    .required(VALIDATION_MESSAGES.REQUIRED),
});

export const validatePasswordSchema = Yup.object({
  validatePassword: Yup.string()
  .required(VALIDATION_MESSAGES.REQUIRED)
  .max(VALIDATION_LENGTH.THIRTY, VALIDATION_MESSAGES.PASSWORD)
  .matches(REGEX.PASSWORD, VALIDATION_MESSAGES.PASSWORD),
});
