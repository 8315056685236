import { API_CONSTANTS } from '../../Constants/apiConstants';
import { RequestService } from './requestServices';

export class CardRequest extends RequestService {
  getVirtualDebitCard = async () => {
    return this.get(API_CONSTANTS.VIRTUAL_DEBIT_CARD);
  };

  postPhysicalCardRequest = async () => {
    return this.post(API_CONSTANTS.PHYSICAL_CARD_REQUEST);
  };

  getCardPinSetURL = async () => {
    return this.get(API_CONSTANTS.SET_CARD_PIN);
  };

  putLockAndUnlockCard = async (data:  {activity: string, reason?: { lock_reason?: string}}) => {
    return this.put(`${API_CONSTANTS.CARD}/${data.activity}`, data.reason);
  };

  getDebitCardDetail = async () => {
    return this.get(API_CONSTANTS.CARD);
  };

  reissueCard = async (data: { reissue_reason: string }) => {
    return this.put(API_CONSTANTS.REISSUE_CARD, data);
  };
}
