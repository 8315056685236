import { ADDRESS_DATA } from '../../Constants/constants';

// Divide address filed.
export const getGoogleAddress = (val: any) => {
  let state = { value: '', label: '' };
  let postalCode = '';
  let address1 = '';
  let city = '';
  let route = '';
  let street = '';
  val &&
    val?.address_components?.forEach((element: any) => {
      const locationType = element.types;
      if (locationType.includes(ADDRESS_DATA.STATE)) {
        state = { value: element.short_name, label: element.long_name };
      }
      if (locationType.includes(ADDRESS_DATA.CITY)) {
        city = element.long_name;
      }
      if (locationType.includes(ADDRESS_DATA.ROUTE)) {
        route = element.long_name;
      }
      if (locationType.includes(ADDRESS_DATA.POSTAL_CODE)) {
        postalCode = element.long_name;
      }
      if (locationType.includes(ADDRESS_DATA.STREET)) {
        street = element.long_name;
      }
      address1 = `${street} ${route}`;
    });
  return {
    state,
    city,
    route,
    postalCode,
    address1,
    street,
  };
};
