import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { debitCard } from '../../../../Constants/reducerEnums';
const initialState: any = {
    card: null,
};
export const cardDetailsSlice = createSlice({
    name: debitCard.cardDetails,
    initialState,
    reducers: {
        setCardDetails: (state, action: PayloadAction<{}>) => {
            state.card = action.payload; // State function for setting data in reducers.
        },
        resetCardDetils: (state) => {
            state.card = null; // Reset Card Data.
        },
    },
});

export const { setCardDetails, resetCardDetils } = cardDetailsSlice.actions;
export default cardDetailsSlice.reducer;
