import { cookiesExpireTime } from '../../Constants/constants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Function is used to set the cookie.
 * @param name - name of the cookie.
 * @param value - value you want to set in cookies.
 */

export const setCookie = (name: string, value?: string | number | boolean) => {
  cookies.set(name, value, {
    path: '/',
    expires: new Date(Date.now() + cookiesExpireTime),
  });
};

/**
 * Function for getting cookie.
 * @param name - name of the cookie you want to get.
 * @returns cookie if available otherwise returns null.
 */

export const getCookie = (name: string) => {
  return cookies.get(name);
};

/**
 * Function for delete a particular cookie.
 * @param name - cookie name you want to delete.
 */

export const deleteCookie = (name: string) => {
  cookies.remove(name);
};

/**
 * Function for delete all cookies.
 */

export const deleteAllCookies = () => {
  const getCookies = document.cookie.split(';');
  for (const element of getCookies) {
    const cookie = element;
    const equalPosition = cookie.indexOf('=');
    const name =
      equalPosition > -1 ? cookie.substring(0, equalPosition) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};
