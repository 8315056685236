import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { SUCCESS_TOAST, VALIDATION_MESSAGES } from '../../../Constants/messages';
import { changePasswordInitialValues } from '../../../Constants/initialValues';
import { changePasswordSchema } from '../../../Constants/validationSchema';
import { getErrorClassName, hideShowPassword } from '../../../Container/Helpers/commonHelpers';
import { AuthServices } from '../../../Container/Services/authServices';
import { ChangePasswordPayload } from '../../../Entities/userStoreTypes';
import LoadingButton from '../Shared/Buttons/loadingButton';
import FormikController from '../Shared/Formik/formikController';
import ProfileDetailsModal from '../Shared/Modals/profileDetailsModal';
import { successToast } from '../Shared/Toastify/toaster';

const ChangePassword = (props: any) => {
  const { changePassword, setChangePassword, setActive } = props;
  const [hideCurrentPassword, setHideCurrentPassword] = useState<string>('password');
  const [hideNewPassword, setHideNewPassword] = useState<string>('password');
  const [loading, setLoading] = useState<boolean>(false);

  // Function for change password.
  const onChangePassword = (values: ChangePasswordPayload) => {
    setLoading(true);
    AuthServices.changePasswordService(values).then((res) => {
      if(res) {
        successToast(SUCCESS_TOAST.PASSWORD_UPDATED);
        setChangePassword(false);
        setActive('');
        setHideCurrentPassword('password');
        setHideNewPassword('password');
      };
    }).finally(() => setLoading(false));
  };

  return (
    <>
      {changePassword && (
        <ProfileDetailsModal
          heading='Change Password'
          defaultHeading={true}
          backNavigate={() => {
            setChangePassword(false);
            setActive('');
          }}
        >
          <div className='profile-form' data-testid='change-password'>
            <Formik
              initialValues={changePasswordInitialValues}
              onSubmit={(values: ChangePasswordPayload) => onChangePassword(values)}
              validationSchema={changePasswordSchema}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ errors, touched }) => (
                <Form data-testid='change-password'>
                  {/* Current password */}
                  <div className='row'>
                    <div className='form-col col'>
                      <div className='input-action'>
                        <FormikController
                          hidden={false}
                          type={hideCurrentPassword}
                          name='currentPassword'
                          label='Current Password'
                          control='input'
                          req={true}
                          className={getErrorClassName(
                            touched.currentPassword,
                            errors.currentPassword
                          )}
                        />
                        <span
                          className='input-btn'
                          onClick={() =>
                            hideShowPassword(
                              hideCurrentPassword,
                              setHideCurrentPassword
                            )
                          }
                        >
                          {hideCurrentPassword === 'password' ? 'Show' : 'Hide'}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* New password */}
                  <div className='row'>
                    <div className='form-col col'>
                      <div className='input-action'>
                        <FormikController
                          control='input'
                          name='newPassword'
                          label='New Password'
                          hidden={
                            errors.newPassword === VALIDATION_MESSAGES.PASSWORD
                          }
                          req={true}
                          type={hideNewPassword}
                          className={getErrorClassName(
                            touched.newPassword,
                            errors.newPassword
                          )}
                        />
                        <span
                          className='input-btn'
                          onClick={() =>
                            hideShowPassword(
                              hideNewPassword,
                              setHideNewPassword
                            )
                          }
                        >
                          {hideNewPassword === 'password' ? 'Show' : 'Hide'}
                        </span>
                      </div>
                      <div
                        className={`${
                          errors.newPassword === VALIDATION_MESSAGES.PASSWORD
                            ? 'form-helper-text invlaid-text'
                            : 'form-helper-text'
                        }`}
                      >
                        The password must contain atleast 8 characters with one
                        numeric and one special character.
                      </div>
                    </div>
                  </div>
                  {/* Confirm new password */}
                  <div className='row'>
                    <div className='form-col col'>
                      <FormikController
                        hidden={false}
                        type='password'
                        name='confirmNewPassword'
                        label='Confirm New Password'
                        control='input'
                        req={true}
                        className={getErrorClassName(
                          touched.confirmNewPassword,
                          errors.confirmNewPassword
                        )}
                      />
                    </div>
                  </div>
                  <LoadingButton
                    className='btn btn-primary w-100'
                    isLoading={loading}
                    icon={true}
                    title='Change Password'
                  />
                  <button
                    className='btn btn-danger w-100 mt-3'
                    onClick={() => {
                      setActive('');
                      setChangePassword(false);
                    }}
                  >
                    Cancel
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </ProfileDetailsModal>
      )}
    </>
  );
};

export default ChangePassword;
