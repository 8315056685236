import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ACTIVE_LINKS, BANK_ACCOUNT_STATUS, RIZE_DISCLOSER_STATUS, SYNC_BANK_STATUS, USER_PROFILE_STATUS } from '../../../../../Constants/constants';
import { currencyFormat, getActiveLink, getOptionData } from '../../../../../Container/Helpers/commonHelpers';
import { PlaidServices } from '../../../../../Container/Services/plaidServices';
import { bankResponseToken, navigateBankUrl } from '../../../../../Container/Services/project';
import { AccountProps } from '../../../../../Entities/propsTypes';
import { AUTHPATHS } from '../../../../../Routes/Config/paths';
import OpenSaivyBankAccount from '../openSaivyBankAccount';

const OpenBankAccount = (props: AccountProps) => {
  const {
    isBankSync,
    isMinimumBalance,
    isKycDone,
    setShowTransactions,
    setShowCardDetails,
    showCardDetails,
    setProfileUpdate,
    setChangePassword,
    setActive,
    active,
    setPlaidModal,
    isRizeAccount,
    isRizeDisclouser
  } = props;

  const navigate = useNavigate();

  // Bank account status for sync, created and minimum balance.
  const bankSyncStatus = (isBankSync === SYNC_BANK_STATUS.ALREADY_SYNCED);
  const minimumBankBalance = (isMinimumBalance === USER_PROFILE_STATUS.TWENTYFIVE_DOLLAR_STATUS_TRUE);
  const bankKycStatus = (isKycDone === USER_PROFILE_STATUS.SAIVY_BANK_SUCCESS_STATUS);

  return (
    <>
      {bankSyncStatus && minimumBankBalance ? (
        <>
          {bankKycStatus && minimumBankBalance ? (
            <div className='account-container'>
              <div className='account-label'>My Saving Account</div>
              <div className='account-value'>{currencyFormat('0')}</div>
              <div className='account-subtitle'>
                <span>{currencyFormat('0')}</span> interest earned
              </div>
              <div
                className='card-btn'
                onClick={() => {
                  setShowTransactions(false);
                  setShowCardDetails(!showCardDetails);
                  setProfileUpdate(false);
                  setChangePassword(false);
                  setActive('');
                }}
              >
                <span>See Account Details</span>
              </div>
            </div>
          ) : (
            <OpenSaivyBankAccount
              onClick={() => {
                if(props.isRizeDisclouser === RIZE_DISCLOSER_STATUS.DONE) {
                  navigate(AUTHPATHS.KYC_PROCESS);
                } else if(props.isRizeAccount === BANK_ACCOUNT_STATUS.ALREADY_CREATED) {
                  navigate(AUTHPATHS.RISE_DISCLOSURE);
                }else if(bankSyncStatus) {
                  if( minimumBankBalance ) {
                    navigate(AUTHPATHS.ACCOUNT_ELIGIBILITY);
                  } else {
                    navigate(AUTHPATHS.ACCOUNT_NOT_ELIGIBLE);
                  }
                } else {
                  navigate(AUTHPATHS.ACCOUNT_CREATE);
                }
              }}
            />
          )}
          <div className='bank-info-container'>
            <div className='bank-name'>Bank Of America</div>
            <div className='card-number'>XXXX XXXX XXXX 5203</div>
            <div
              className={getActiveLink(active)}
              onClick={() => {
                setActive(ACTIVE_LINKS.ONE);
                PlaidServices.createToken().then((res: any) => {
                  bankResponseToken(res, setPlaidModal);
                });
              }}
            >
              {getOptionData(bankSyncStatus, 'Change Primary Account', 'Sync Primary Account')}
            </div>
          </div>
        </>
      ) : (
        <OpenSaivyBankAccount
          onClick={() => {
            if(isBankSync === USER_PROFILE_STATUS.SAIVY_BANK_FAILURE_STATUS) {
              PlaidServices.createToken().then((res: any) => {
                bankResponseToken(res, setPlaidModal); // Saving Bank link token in Cookies.
              });
            } else {
              navigateBankUrl(minimumBankBalance, navigate);
            }
          }
          }
        />
      )}
    </>
  );
};

export default OpenBankAccount;
