import React from 'react';
import { TransactionTypes } from '../../../Entities/propsTypes';
import { currencyFormat, dateFormater, getOptionData } from '../../../Container/Helpers/commonHelpers';
import { TRANSACTION_TYPE } from '../../../Constants/constants';

const TransactionListing = (props: {
  setPage: (val: number) => void;
  page: number;
  transactions: any
  loading?: boolean;
}) => {

  const depositeTransactionType = (value: string) => {
    return value === 'positive' || value;
  };

  return (
    <>
      {props?.transactions?.data?.length > 0 ? (
        <div className='transaction-list mb-3'>
          {props?.transactions?.data &&
            props.transactions.data.map((value: TransactionTypes) => {
              return (
                <div className='transaction-list-item' key={value.id}>
                  <div>
                    <div className='transaction-title'>
                      {depositeTransactionType(value.net_asset || TRANSACTION_TYPE.DEPOSITE)
                        ? 'Deposit'
                        : 'Withdrawal'}
                    </div>
                    <div className='transaction-date'>
                      {dateFormater((value.created_at || value?.execution_at), 'DD MMM YYYY')}
                    </div>
                  </div>
                  <div
                    className={`transaction-amount ${
                      depositeTransactionType(value.net_asset || TRANSACTION_TYPE.DEPOSITE) && 'credited'
                    }`}
                  >
                    {getOptionData(depositeTransactionType(value?.net_asset || TRANSACTION_TYPE.DEPOSITE), '+', '-')}{' '}
                    {currencyFormat(value?.us_dollar_amount || value?.amount)}
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <>
        {!props.loading && (
          <div className='no-result-layout'>
            <div className='no-result-title'>No Transactions Yet</div>
          </div>
        )}
        </>
      )}
    </>
  );
};

export default TransactionListing;
