import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
// Local Import
import greenArrow from '../../../../../Assets/Images/green-arrow.svg';
import MailingAddress from '../mailingAddress';
import FormModal from '../../../Modals/formModal';
import { CardServiceProvider } from '../../../../../../Container/Services/cardServices';
import lockIcon from '../../../../../Assets/Images/lock-icon.svg';
import { setCardDetails } from '../../../../../../DataSources/Redux/Slices/DebitCard/cardDetails';
import { useWebDispatch, useWebSelector } from '../../../../../../DataSources/Redux/Store/hooks';
import { IMailingAddressTypes } from '../../../../../../Entities/mailingAddressTypes';
import { MailingAddressServices } from '../../../../../../Container/Services/mailingAddressServices';
import { mailingAddressPayload } from '../../../../../../Container/Services/project';
import { successToast } from '../../../Toastify/toaster';
import { BANK_ACCOUNT_STATUS } from '../../../../../../Constants/constants';
import backBtn from '../../../../../Assets/Images/profile-back.svg';
import LoadingButton from '../../../Buttons/loadingButton';
import { SUCCESS_TOAST } from '../../../../../../Constants/messages';

const PhysicalCard = (props: { showCardDetails: any, setOpen: (val: boolean) => void, open: boolean, setShowCardDetails: (val: boolean) => void}) => {
  const dispatch = useWebDispatch();
  const [cardimage, setCardImage] = useState('');
  const [show, setShow] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const { card } = useWebSelector(
    (cardDetails) => cardDetails?.debitCardDetails
  );
  const [unLock, setLock] = useState<boolean>(card?.lock_reason && true);
  const [loading, setLoading] = useState<boolean>(false);
  const { profile } = useWebSelector((userProfile) => userProfile?.profile);
  const profileData = profile?.data?.user;

  // Handle On Submit Form
  const handleSubmit = (values: IMailingAddressTypes) => {
    setLoading(true);
    MailingAddressServices.postMailingAddressData(
      mailingAddressPayload(values)
    ).then((response) => {
      if (response) {
        CardServiceProvider.postPhysicalCardRequest()
          .then(() => {
            successToast(SUCCESS_TOAST.MAILING_ADDRESS_SUCCESS);
            CardServiceProvider.getDebitCardDetails().then((res) => {
              if(res) {
                dispatch(setCardDetails(res));
              }
            });
            setShow(false);
          });
      }
    }).finally(() => setLoading(false));
  };

 const mailingAddress = card?.latest_shipping_address?.street1;

  useEffect(() => {
    if (profileData?.rize_account_status === BANK_ACCOUNT_STATUS.ALREADY_CREATED) {
      CardServiceProvider.getVirtualDebitCard().then((res: any) => {
        if (res) {
          setCardImage(res);
          CardServiceProvider.getDebitCardDetails().then((response: any) => {
            if( response ) {
              dispatch(setCardDetails(response));
            }
          });
        }
      });
    }
  }, []);

  // Modal open and close functions.
  const onModalOpen = () => {
    setShow(true);
  };

  return (
    <>
      <div className='profile-detail-modal' hidden={!props.showCardDetails}>
        <div className='profile-back'  onClick={() => props.setShowCardDetails(false)}>
          <img src={backBtn} alt='back'/>
        </div>
        <div className='profile-title text-md-center'>My Saving Account</div>
        <div className='card-container'>
          {/* "locked" class in case card is locked */}
          <div className={`card-img ${unLock ? 'locked' : ''}`}>
            <img src={cardimage} alt='card' />
            {unLock && (
              <div className='lock-icon'>
                <img src={lockIcon} alt='lock' />
              </div>
            )}
          </div>

          <div className='card-lock-row'>
            <div className='lock-text'>Lock My Card</div>
            <div className='custom-switch'>
              <Form.Check
                type='switch'
                id='custom-switch'
                checked={unLock}
                onChange={() => {
                  setLock(!unLock);
                  if(card?.lock_reason && unLock) {
                    CardServiceProvider.putLockAndUnlockCard({ activity: 'unlock' }).then(() => {
                      CardServiceProvider.getDebitCardDetails().then((response: any) => {
                        if( response ) {
                          dispatch(setCardDetails(response));
                        }
                      });
                    });
                  }
                }}
              />
            </div>
            {/* Lock reason div */}
            {(unLock && !card?.lock_reason )? (
              <div className='lock-reason-container'>
                <div className='form-col'>
                  <label className='form-label'>
                    Reason<span className='red-star'>*</span>
                  </label>
                  <textarea
                    className='form-control'
                    rows={4}
                    onChange={(e: any) => {
                      setReason(e.target.value);
                    }}
                    value={reason}
                  ></textarea>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <button
                    className='btn btn-danger'
                    type='button'
                    onClick={() => setLock(false)}
                  >
                    Cancel
                  </button>
                  <LoadingButton
                    className='btn btn-primary mt-0 px-5'
                    onClick={() => {
                      setLoading(true);
                      if (!card?.lock_reason && unLock) {
                        CardServiceProvider.putLockAndUnlockCard({ activity: 'lock', reason}).then((res) => {
                          CardServiceProvider.getDebitCardDetails().then((response: any) => {
                            if( response ) {
                              dispatch(setCardDetails(response));
                              setReason('');
                            }
                          });
                        }).finally(() => setLoading(false));
                      }
                    }}
                    isLoading={loading}
                    title='Submit'
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {mailingAddress ? (
            ''
          ) : (
            <button className='card-button' onClick={() => setShow(true)}>
              Request for a Physical Card{' '}
              <img className='btn-icon' src={greenArrow} alt='icon' />
            </button>
          )}
          <button
            className='card-button'
            onClick={() =>
              CardServiceProvider.getCardPinSetURL().then((res: any) => {
                window.open(res.pin_set_url, '_blank');
              })
            }
          >
            Set Card Pin
          </button>
          { (card?.type === 'physical') && (
            <button className='card-button' onClick={() => props.setOpen(!props.open)}>Reissue My Card</button>
          )}
        </div>
      </div>
      <FormModal show={show} onShow={() => onModalOpen()}>
        <MailingAddress
          onSubmit={handleSubmit}
          onClick={() => setShow(false)}
          loading={loading}
        />
      </FormModal>
    </>
  );
};

export default PhysicalCard;
