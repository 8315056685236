import { API_CONSTANTS } from '../../Constants/apiConstants';
import { IPlaidExchangeToken, IRetrieveAccount } from '../../Entities/bankTypes';
import { RequestService } from './requestServices';

export class PlaidCreateTokenRequest extends RequestService {
    async createToken() {
        return this.post(API_CONSTANTS.CREATE_TOKEN);
    }
};

export class PlaidExchangeToken extends RequestService {
    async exchangeToken(data: IPlaidExchangeToken) {
        return this.post(`${API_CONSTANTS.EXCHANGE_TOKEN}?public_token=${data?.public_token}`);
    }
};

export class PlaidRetrieveAccount extends RequestService {
    async retrieveAccount(data: IRetrieveAccount) {
        return this.post(`${API_CONSTANTS.RETRIEVE_BANK}?access_token=${data?.access_token}`);
    }
};
