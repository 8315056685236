import { currencyFormat } from './commonHelpers';

export const getUpdatedData = (zillowData: any, unit: string, address: { city: string, state: string, address: string}) => {
    return {
        address_line_1: address?.address,
        address_line_2: '',
        unit_number: zillowData?.unit || unit,
        state: address?.state || zillowData?.state,
        city: address.city || zillowData?.city,
        zestimate_range: zillowData?.rent_estimate,
        home_value: zillowData?.zestimate,
        total_square_feet: zillowData?.lot_size,
        zestimate_change: zillowData?.zestimate_change,
        property_type: zillowData?.property_type,
        living_square_feet: zillowData?.areas?.interior_living_space,
        bathroom_count: zillowData?.full_bathrooms,
        half_bathroom: zillowData?.half_bathrooms,
        bedroom_count: zillowData?.bedrooms,
        year_built: zillowData?.built_year,
        lot_size: zillowData?.lot_size,
        foundation: zillowData?.foundation,
        roof: zillowData?.roof,
        heating: zillowData?.heating,
        total_stories: zillowData?.stories_number,
        parking: zillowData?.parking?.[0]?.areaSquareFeet,
        construction_material: zillowData?.construction_material,
        cooling: zillowData?.cooling,
    };
};

// Get value according to the data is null or not.
// If value is not null then it will return '-' else return value.
export const getNullData = (value: string | undefined) => {
    return value ? value: '-';
};

// Get value according to the data is null or not.
// If value is not null then it will return 0 else return value.
export const numberFormat = (value: number) => {
    return value ? new Intl.NumberFormat('en-US').format(value) : '-';
};

export const numberWithoutFormat = (value: number) => {
    return value ? value : '-';
};

export const getCurrencyData = (value: string | undefined) => {
    return value ? currencyFormat(value) : '-';
};
