import React from 'react';
import Select from 'react-select';
import { IDropdownType } from '../../../../Entities/propsTypes';

const Dropdown = (props: IDropdownType) => {
    const { onChange, options, className, placeholder, value, isSearchable, defaultValue} = props;

  return (
    <>
      <Select
        isClearable={false}
        isSearchable={isSearchable}
        onChange={(e) => onChange(e)}
        options={options}
        className={className}
        placeholder = {placeholder}
        classNamePrefix="tenure-select"
        defaultValue={defaultValue}
        value={value}
      />
    </>
  );
};

export default Dropdown;
