import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { property } from '../../../../Constants/reducerEnums';

const initialState: any = {
  propertyData: null,
  activeProperty: {
    id: '',
    address: ''
  },
};

// Reducer for property data.
export const propertyDataSlice = createSlice({
  name: property.getPropertyData,
  initialState,
  reducers: {
    setPropertyData: (state, action: PayloadAction<{}>) => {
      state.propertyData = action.payload; // State function for setting data in reducers.
    },
    setSeletedProperty: (state, action: PayloadAction<{}>) => {
      state.activeProperty = action.payload; // State function for setting the selected property data.
    },
    resetPropertyData: (state) => {
      state.propertyData = null; // Reset property Data.
    },
  },
});

export const { setPropertyData, resetPropertyData, setSeletedProperty } = propertyDataSlice.actions;
export default propertyDataSlice.reducer;
