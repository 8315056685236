import React from 'react';
import { Navigate } from 'react-router-dom';

//local imports
import { AUTHPATHS } from './Config/paths';
const Dashboard = React.lazy(() => import('../UI/Components/Dashboard/index'));
const PropertyAddress = React.lazy(
  () => import('../UI/Components/Property/PropertyAddress/index')
);
const SearchLocation = React.lazy(
  () => import('../UI/Components/Address/searchLocation')
);
const AddProject = React.lazy(
  () => import('../UI/Components/Projects/AddProject/projectName')
);
const CostEstimates = React.lazy(
  () => import('../UI/Components/Projects/Estimates/index')
);
const RizeDisclosure = React.lazy(()=> import('../UI/Components/Bank/Rize/rizeDisclosure'));
const Kyc = React.lazy(()=> import('../UI/Components/Bank/Kyc/index'));
const SaivyAccount  = React.lazy(()=> import('../UI/Components/Bank/SaivyAccount/index'));
const AccountEligibility = React.lazy(() => import('../UI/Components/Bank/SaivyBank/eliglbleAccount'));
const PaymentType = React.lazy(() => import('../UI/Components/Plaid/paymentType'));
const CustomSaving = React.lazy(() => import('../UI/Components/Plaid/customSaving'));
const NotEligible = React.lazy(() => import('../UI/Components/Bank/SaivyBank/notEligible'));
const TenureSelection = React.lazy(() => import('../UI/Components/Plaid/tenureSelection'));
const OneTimeDeposite = React.lazy(() => import('../UI/Components/Transcations/createTransaction'));

const PrivateRoutes = [
  { path: AUTHPATHS.DASHBOARD, exact: true, element: <Dashboard /> },
  {
    path: AUTHPATHS.DEFAULT,
    exact: true,
    element: <Navigate replace to={AUTHPATHS.DASHBOARD} />,
  },
  {
    path: AUTHPATHS.CALLBACK,
    exact: true,
    element: <Navigate replace to={AUTHPATHS.DASHBOARD} />,
  },

  { path: AUTHPATHS.ADD_PROPERTY, exact: true, element: <PropertyAddress /> },
  {
    path: AUTHPATHS.ACCOUNT_CREATE,
    exact: true,
    element: <SaivyAccount />,
  },
  {
    path: AUTHPATHS.SEARCH_LOCATION,
    exact: true,
    element: <SearchLocation />,
  },
  {
    path: AUTHPATHS.ADD_PROJECT,
    exact: true,
    element: <AddProject />,
  },
  {
    path: `${AUTHPATHS.COST_ESTIMATES}/:id`,
    element: <CostEstimates />,
  },
  {
    path: AUTHPATHS.ACCOUNT_ELIGIBILITY,
    element: <AccountEligibility />,
  },
  {
    path: AUTHPATHS.RISE_DISCLOSURE,
    element: <RizeDisclosure />,
  },
  {
    path: AUTHPATHS.KYC_PROCESS,
    element: <Kyc/>,
  },
  {
    path: `${AUTHPATHS.PAYMENT_TYPE}/:id`,
    element: <PaymentType />,
  },
  {
    path: `${AUTHPATHS.CUSTOM_SAVING}/:id`,
    element: <CustomSaving />,
  },
  {
    path: AUTHPATHS.ACCOUNT_NOT_ELIGIBLE,
    element: <NotEligible />,
  },
  {
    path: `${AUTHPATHS.TENURE_SELECTION}/:id`,
    element: <TenureSelection />,
  },
  {
    path: `${AUTHPATHS.ONE_TIME_DEPOSITE}/:id`,
    element: <OneTimeDeposite />,
  }
];

export default PrivateRoutes;
