import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { project } from '../../../../Constants/reducerEnums';

const initialState: any = {
  list: null,
};

// Reducer for Project data.
export const projectListSlice = createSlice({
  name: project.projectListing,
  initialState,
  reducers: {
    setProjectList: (state, action: PayloadAction<{}>) => {
      state.list = action.payload; // State function for setting data in reducers.
    },
    resetProjectList: (state) => {
      state.list = null; // Reset Project Data.
    },
  },
});

export const { setProjectList, resetProjectList } = projectListSlice.actions;
export default projectListSlice.reducer;
