import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_STATUS, UNAUTHORIZED_TOAST_TIMESTAMP } from '../../Constants/constants';
import { getToastError } from '../../Container/Helpers/commonHelpers';
import { deleteAllCookies, getCookie } from '../../Container/Services/cookiesServices';
import { COMMONPATHS } from '../../Routes/Config/paths';
import { errorToast } from '../../UI/Components/Shared/Toastify/toaster';
import { startLoader, stopLoader } from '../Redux/Slices/Loader/loadingSlice';
import store from '../Redux/Store/store';

export class Interceptors {

    static requestInterceptor(request: any) {
        store.dispatch(startLoader());
        const token = getCookie('access_token');
        const headers = {
            'Content-Type': 'application/json'
        };
        if (token) {
            request.headers = { Authorization: `Bearer ${token}`, ...headers };
        } else {
            request.headers = { ...headers };
        }
        request.data = JSON.stringify(request.data);

        return request;
    }

    static responseInterceptor(r: AxiosResponse) {
        store.dispatch(stopLoader());
        const response = JSON.parse(r.data);
        if (response.status) {
            return response.data;
        } else {
            const { key } = response;
            errorToast(getToastError(key));
            if(response.status_code === API_STATUS.UNAUTHORIZED) {
                setTimeout(() => {
                    window.location.href = COMMONPATHS.LOGIN;
                    deleteAllCookies();
                }, UNAUTHORIZED_TOAST_TIMESTAMP);
            }
            return null;
        }
    }
};
