import { Field, ErrorMessage } from 'formik';
import OtpInput from 'react18-input-otp';
import {
  InputPropsType,
  IOtpInputTypes,
} from '../../../../Entities/InputInterface';

export const Input = (props: InputPropsType) => {
  const { name, label, className, hidden, req, ...rest } = props;
  return (
    <>
      <label className='form-label'>
        {label}
        <span className='red-star'>{req ? '*' : null}</span>
      </label>
      <Field className={className} name={name} {...rest} />
      <div className='validation-error' hidden={hidden}>
        <ErrorMessage name={name} />
      </div>
    </>
  );
};

export const Textarea = (props: InputPropsType) => {
  const { label, name, ...rest } = props;
  return (
    <>
      <label htmlFor={name}>{label}</label>
      <Field as='textarea' id={name} name={name} {...rest} />
      <p>
        <ErrorMessage name={name} />
      </p>
    </>
  );
};

export const CustomOtpInput = (props: IOtpInputTypes) => {
  const { onChange, value, hasErrored, className } = props;
  return (
    <>
      <OtpInput
        numInputs={6}
        separator={<span className='otp-input-seprator'>-</span>}
        onChange = {onChange}
        value = {value}
        hasErrored = {hasErrored}
        isInputNum = {true}
        placeholder = '000000'
        className={className}
      />
    </>
  );
};

export const CardTextArea = (props: InputPropsType) => {
  const { label, name, req, ...rest } = props;
  return (
    <>
      <label className='form-label'>
        {label}
        <span className='red-star'>{req ? '*' : null}</span>
      </label>
      <Field as='textarea' id={name} name={name} {...rest}/>
      <p>
        <ErrorMessage name={name} />
      </p>
    </>
  );
};
