import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoutes from '../privateRoutes';

const AuthRoutes = () => {
  return (
    <Suspense>
      <Routes>
        {PrivateRoutes.map((route, key) => {
          return <Route key={key} {...route} />;
        })}
      </Routes>
    </Suspense>
  );
};

export default AuthRoutes;
