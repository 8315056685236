import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { deleteAllCookies, getCookie } from '../../Container/Services/cookiesServices';
import { API_STATUS, UNAUTHORIZED_TOAST_TIMESTAMP } from '../../Constants/constants';
import { errorToast } from '../../UI/Components/Shared/Toastify/toaster';
import { getToastError } from '../../Container/Helpers/commonHelpers';
import { COMMONPATHS } from '../../Routes/Config/paths';
import { API_ERROR_KEYS } from '../../Constants/errorKeys';
import { ERROR_MESSAGES } from '../../Constants/messages';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (request: any) => {
    const token = getCookie('access_token');
    if (token) {
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return request;
  },
  (error) => {
    const status = error?.response?.status || API_STATUS.INTERNAL_SERVER;
    if (status === API_STATUS.INTERNAL_SERVER) {
      alert(ERROR_MESSAGES.INTERNAL_SERVER_ERROR);
    }
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async function (error) {
    const status = error?.response?.status || API_STATUS.INTERNAL_SERVER;
    const key = error?.response?.data?.key;
    switch (status) {
      case API_STATUS.BAD_REQUEST:
      case API_STATUS.INTERNAL_SERVER:
      case API_STATUS.NOT_FOUND:
       case API_STATUS.FORBIDDEN:
        if (key === 'LIST_DATA_NOT_FOUND') {
          return Promise.reject(error);
        } else {
          errorToast(getToastError(key));
        }
        break;
      case API_STATUS.UNAUTHORIZED:
        errorToast(getToastError(key));
        setTimeout(() => {
          window.location.href = COMMONPATHS.LOGIN;
          deleteAllCookies();
        }, UNAUTHORIZED_TOAST_TIMESTAMP);
        break;
      case API_STATUS.VALIDATION_REQUEST_FAILED:
        errorToast(getToastError(key) || API_ERROR_KEYS.UNABLE_TO_PROCESSED);
        break;
      default:
        return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
