import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { bankDetails } from '../../../../Constants/reducerEnums';

const initialState: any = {
  transactions: null,
  projectTransaction: []
};

// Reducer for transactions data.
export const transactionSlice = createSlice({
  name: bankDetails.transactions,
  initialState,
  reducers: {
    setTransactionList: (state, action: PayloadAction<{}>) => {
      state.transactions = action.payload; // State function for setting data in reducers.
    },
    setProjectTransaction: (state, action: PayloadAction<{}>) => {
      state.projectTransaction = action.payload; // State function for setting particular project transaction data.
    },
    resetTransactionList: (state) => {
      state.transactions = null; // Reset transactions data.
      state.projectTransaction = [];
    },
  },
});

export const { setTransactionList, resetTransactionList, setProjectTransaction } = transactionSlice.actions;
export default transactionSlice.reducer;
