import { PropertyRequest } from '../../DataSources/ApiServices/propertyRequest';

export class PropertyServices {
  private static readonly propertyRequest: PropertyRequest = new PropertyRequest();

  // Property Details.
  static async getPropertyDetails(id: string) {
    return this.propertyRequest.getProperty(id);
  }

  // Add New Property.
  static async addNewProperty(data: any) {
    return this.propertyRequest.addProperty(data);
  }

  // Get zillow data from address.
  static getZillowDataService = async(data: { address: string, unit: number}) => {
    return this.propertyRequest.getZillowData(data);
  };
};
