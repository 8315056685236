export const API_CONSTANTS = {
  ADD_PROPERTY: 'property/create',
  PROPERTY_LISTING: 'property/list',
  USER_PROFILE: 'profile',
  COST_ESTIMATES: 'project/update/estimate',
  COST_ESTIMATES_POST: 'remodelling/estimates',
  REMODELLING_TYPE: 'remodelling/type',
  CREATE_PROJECT: 'project/create',
  EXTERNAL_PROPERTY_DETAILS: 'property/external/details',
  PROJECT_LIST_PROPERTY: 'property',
  CREATE_TOKEN: 'create/token',
  EXCHANGE_TOKEN: 'token/exchange',
  RETRIEVE_BANK: 'retrieve/account',
  CREATE_ACCOUNT: 'create/account',
  CUSTOM_ESTIMATES: 'project/custom/estimate',
  RISE_DISCLOSURE:'account/workflow',
  RISE_DISCLOSURE_ACKNOWLEDGE:'account/acknowledge',
  UPLOAD_KYC_DOCUMENTS:'account/kyc',
  KYC_LIST_EVALUATION:'account/evaluation',
  ACCOUNT_CONFIRMATION:'account/customer',
  SYNTHETIC_ACCOUNT:'external/synthetic/account',
  TENURE_SELECTION: 'transaction',
  SET_TENURE: 'transaction/emi/create',
  PROPERTY_DETAILS: 'property/details',
  MAILING_ADDRESS:'card/mailing/address',
  VIRTUAL_DEBIT_CARD:'virtual/card',
  PHYSICAL_CARD_REQUEST:'physical/card',
  SET_CARD_PIN: 'card/set/pin',
  SEGREGATION_ESTIMATES: 'project/estimates',
  CARD: 'card',
  REISSUE_CARD: 'reissue/card'
};

export const AUTH_API_CONSTANTS = {
  LOGOUT: 'logout',
  REGISTER: 'register',
  LOGIN: 'login',
  OTP_VERIFICATION: 'otp/validate',
  RESEND_OTP: 'resend/otp',
  CHANGE_PHONE_NUMBER: 'change/phone/number',
  VERIFY_EMAIL: 'email/verify',
  SEND_VERIFY_EMAIL: 'verify/email/request',
  CHANGE_PASSWORD: 'change/password',
  FORGOT_PASSWORD: 'password/forgot/send/code',
  FORGOT_PASSWORD_RESET_TOKEN: 'password/forgot/reset/token',
  PASSWORD_RESET: 'password/reset'
};

export const USER_API_CONSTANTS = {
  UPDATE_PROFILE: 'update/profile',
  USER_PROFILE: 'profile',
};

export const TRANSACTIONS_API_CONSTANTS = {
  ONE_TIME_DEPOSITE: 'transaction/create',
  TRANSACTION_LISTING: 'transaction/list',
  TRANSACTION_CANCEL: 'transaction/emi/cancel',
  TRANSACTION_PAUSE: 'transaction/emi/pause',
  TRANSACTION_SKIP: 'transaction/emi/skip',
  PROJECT_TRANSACTION: 'transaction',
};
