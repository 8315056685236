import React from 'react';
import { ProfileDetailsTypes } from '../../../../Entities/propsTypes';
import backBtn from '../../../Assets/Images/profile-back.svg';

const ProfileDetailsModal = (props: ProfileDetailsTypes) => {
  const { heading, children, headingClass, backNavigate, defaultHeading } = props;
  return (
    <div className='profile-detail-modal'>
      <div className='profile-back'>
        <img src={backBtn} alt='back' onClick={() => backNavigate()} />
      </div>
      {defaultHeading && <div className={`profile-title text-${headingClass}`}>{heading}</div>}
      {children}
    </div>
  );
};

export default ProfileDetailsModal;
