import {PlaidCreateTokenRequest, PlaidExchangeToken, PlaidRetrieveAccount } from '../../DataSources/ApiServices/plaidRequest';
import { IPlaidExchangeToken, IRetrieveAccount } from '../../Entities/bankTypes';

export class PlaidServices {
    private static readonly plaidRequest: PlaidCreateTokenRequest = new PlaidCreateTokenRequest();
    private static readonly plaidExchangeToken: PlaidExchangeToken = new PlaidExchangeToken();
    private static readonly plaidRetrieveBank: PlaidRetrieveAccount = new PlaidRetrieveAccount();

    //Create Token for plaid.
    static async createToken() {
        return this.plaidRequest.createToken();
    };

    // Exchange Plaid public token for getting bank access_token.
    static async exchangeToken(data: IPlaidExchangeToken) {
        return this.plaidExchangeToken.exchangeToken(data);
    }

    // Retrieve Account using bank_access_token.
    static async retrieveAccount(data: IRetrieveAccount) {
        return this.plaidRetrieveBank.retrieveAccount(data);
    }

};
