import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { project } from '../../../../Constants/reducerEnums';

const initialState: any = {
  data: null,
};

// Reducer for Project data.
export const customSegragation = createSlice({
  name: project.customSegregation,
  initialState,
  reducers: {
    setSegregationEstimates: (state, action: PayloadAction<{}>) => {
      state.data = action.payload; // State function for setting data in reducers.
    },
    resetSegregationEstimates: (state) => {
      state.data = null; // Reset segregation estimate data.
    },
  },
});

export const { setSegregationEstimates, resetSegregationEstimates } = customSegragation.actions;
export default customSegragation.reducer;
