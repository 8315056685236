import React from 'react';

const PropertyDetailProps = (props: {label: string, value?: string | number, unit?: string}) => {
  return (
    <div className='prop-detal-col'>
        <div className='prop-label'>{props.label}:</div>
        <div className='prop-value'>{` ${props.value} ${props.unit? props.unit: ''}`}</div>
    </div>
  );
};

export default PropertyDetailProps;
