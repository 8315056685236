import { COMMONPATHS } from '../Routes/Config/paths';
import { AUTHPATHS } from './../Routes/Config/paths';

export const HTTP_METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

// Api status codes.
export const API_STATUS = {
  SUCCESS: 200,
  CREATE: 201,
  UNAUTHORIZED: 401,
  INTERNAL_SERVER: 500,
  BAD_REQUEST: 400,
  GATEWAY: 502,
  INTERNET_UNAVAILABLE: 503,
  VALIDATION_REQUEST_FAILED: 422,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
};

// Time in milliseconds.
export const cookiesExpireTime = 86400000;

// Time out time for otpResend.
export const otpResendTimeout = 600000;

export const NUMBERS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  TWELVE: 12
};

export const maxOtpLength = 6;

export const REGEX = {
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  EMAIL:
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  NUMBERS: /^[0-9]+$/,
  ONLY_ALPHABETS: /^[a-zA-Z]+$/,
  ONLY_DECIMAL: /^(\d*\.)?\d+$/igm,
  PHONE_NUMBER: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  CUSTOM_SAVING_INPUT: /[ ^a-zA-Z`!@#%^&*()_+\-=\[\]{};':"\\|<>\/?~]/,
  NUMBER_FORMAT: /[$,]/g
};

// URL Entities for different Headers.
export const urlEntities = {
  formNav: [
    'login',
    'register',
    'verify-otp',
    'search-location',
    'add-property',
    'forgot-password'
  ],
  dashboardNav: ['dashboard']
};

export const buttonName = {
  login: 'Go to Log In',
  register: 'Create Account',
  logout: 'Log Out',
  later: 'Later',
  cancel: 'Cancel'
};

export const headerButton = ['login', 'register', 'search-location', 'add-property', '', 'create-account'];

export const STATUS_CHECK = {
  TRUE: 1,
  FALSE: 0
};

export const ASSCICODE = {
  SIXTY_NINE: 69,
  ONE_HUNDRED_NINTY: 190,
  ONE_HUNDRED_EIGHTY_SEVEN: 187,
  ONE_HUNDRED_EIGHTY_NINE: 189,
};

export const PROPERTY_LENGTH = {
  MIN: 0,
  MAX: 3
};

export const CAROUSEL_SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ],
};

export const hideNavbar = [
  'html', 'add-project-name', 'cost-estimates',
  'account-eligible', 'verify-email', 'custom-saving',
  'payment-type', 'account-not-eligible', 'tenure-selection', 'one-time-deposite', 'rize-disclosure', 'kyc-process'
];

export const UNAUTHORIZED_TOAST_TIMESTAMP = 3000;
export const TOAST_LIMIT = 2;

export const SPLIT_INDEX = {
  FIRST: 1
};

export const TOAST_SUCCESS_MESSAGES = {
  NUMBER_VERIFIED_SUCCESS: 'Phone number verified successfully'
};

export const VALIDATION_LENGTH = {
  ONE: 1,
  TWO: 2,
  TEN: 10,
  EIGHT: 8,
  ONE_FIFTY: 150,
  FIFTY: 50,
  TWENTY_FIVE: 25,
  ZERO: 0,
  HUNDERED: 100,
  NINE:9,
  THIRTEEN: 13,
  THIRTY: 30,
  ELEVEN: 11
};

export const TOASTER_AUTO_CLOSE_TIMESTAMP = 3000;

// Restricted countries for map search results.
export const RESTRICTED_COUNTRIES = ['gu', 'pr', 'vi', 'us', 'ca'];

// Google Maps Search Types.
export const GOOGLE_MAP_SEARCH_TYPES = [
  'political',
  'hospital',
  'administrative_area_level_1',
  'postal_code',
  'route'
];

// Context for search location.
export const SEARCH_LOCATION_CONTEXT = ['street_number', 'locality', 'route', 'administrative_area_level_1', 'postal_code'];

// Default country code.
export const DEFAULT_COUNTRY_CODE = 1;

// Timestamp for Active and Inactive Loader state.
export const LOADING_TIME = {
  START: 10000,
  STOP: 200,
  MIN_LOADING_TIME: 1000
};

// Default value for payload values.
export const DEFAULT_VALUE = 0;

// Error key length.
export const ERROR_KEY_LENGTH = 0;

// Buttons Label Routing URL Array.
export const LATER_BUTTON = [AUTHPATHS.ACCOUNT_CREATE, AUTHPATHS.RISE_DISCLOSURE, AUTHPATHS.KYC_PROCESS];

export const LOGOUT_BUTTON = [ AUTHPATHS.SEARCH_LOCATION, AUTHPATHS.ADD_PROPERTY ];

// Project Model Type.
export const MODEL_TYPE = ['1', '2', '3', '4'];

export const ACCOUNT_INDEX = 0;

// Array for block back browsing/routing.
export const BACK_BROWSING = [COMMONPATHS.VERIFY_EMAIL, COMMONPATHS.VERIFY_OTP];

// Email Verify Status;
export const EMAIL_VERIFY_STATUS = {
  YES: 'true',
  NO: 'false'
};

// Bank Connect Status.
export const SYNC_BANK_STATUS = {
  ALREADY_SYNCED: 1,
  NOT_SYNCED: 0
};

export const BUTTON_COLOR = [ buttonName.logout, buttonName.later, buttonName.cancel ];

// Project Types.
export const ESTIMATES_TYPES = {
  KITCHEN_REMODEL: 'Kitchen Remodel',
  BATHROOM_REMODEL: 'Bathroom Remodel',
  FLOOR_REPLACEMENT: 'Floor Replacement',
  GENERAL_MAINTENANCE: 'General Maintenance'
};

// Active Estimates Card.
export const ACTIVE_ESTIMATE_CARD = {
  FIRST: '1',
  SECOND: '2',
  THIRD: '3',
  FOURTH: '4',
  FIFTH: '5',
  SIXTH: '6',
  SEVENTH: '7'
};

// Payment type.
export const PAYMENT_TYPE = {
  ONE_TIME: 0,
  RECURRING: 1
};

// Rize fs account status.
export const BANK_ACCOUNT_STATUS = {
  ALREADY_CREATED: 1,
  NOT_CREATED: 0
};

// Minimum balance status for plaid account.
// If minimum balance is 25 dollar the status will be false or status will be true that is coming from backend.
export const IS_MINIMUM_BALANCE = {
  TRUE: 1,
  FALSE: 0
};

// Status for navigation from project draft listing.
export const STEP_FILLED_STATUS = {
  SYNC_BANK : 'sync_primary_account',
  CREATE_ACCOUNT: 'create_rize_account',
  RIZE_DISCLOUSER: 'accpet_rize_disclosure',
  CUSTOM_COST_ESTIMATE: 'choose_custom_amount',
  PROJECT_TYPE_STEP: 'choose_project_type',
  ESTIMATION_TYPE_STEP:'choose_estimation_type',
  KYC_STATUS: 'choose_kyc_status',
  EMI_CREATED: 'project_emi_created',
  RECURRING_PAYMENT_MODE: 'recurring_payment_mode'
};

// Project state status
export const PROJECT_STATE_STATUS = {
  DRAFT: 'draft',
  ONGOING: 'ongoing',
  COMPLETED: 'completed',
  CANCELED: 'cancelled',
  SKIPPED: 'skip',
  PAUSE: 'pause',
};

// Project pagination range.
export const PAGINATION_RANGE = {
  EXCEED_RANGE: 1,
};
export const DATE_FORMAT = 'MMM d, yyyy';

// Custom saving input constants for formatting the currency in USD format.
export const CURRENCY_FORMATER = {
  MINIMUM_FRACTION: 2,
  DEFAULT_AMOUNT_VALUE: 0
};

// Transaction modal clicked button status.
export const BUTTON_STATUS = {
  CANCEL: 'cancel',
  SKIP: 'skip',
  PAUSE: 'pause',
  RESUME: 'resume'
};

// Remodelling project type keys.
export const REMODELLING_PROJECT_KEYS = {
  BATHROOM: 'bathroom_remodel',
  KITCHEN: 'kitchen_remodel',
  FLOOR: 'floor_replacement',
  MAINTENANCE: 'general_maintenance'
};

// Custom saving minimum value;
export const CUSTOM_SAVING = {
  MIN: 1
};

export const MIN_TENURE = 6;
export const GENERAL_TENURE = 12;

// Transaction status.
export const RECURRING_STATUS = {
  RECURRING: 0,
  ONE_TIME: 1
};

// Project status.
export const PROJECT_STATUS = ['cancelled', 'completed'];

// Active links.
export const ACTIVE_LINKS = {
  ONE: '1',
  TWO: '2',
  FOUR: '4',
  FIVE: '5'
};

export const USER_PROFILE_STATUS ={
  SAIVY_BANK_SUCCESS_STATUS: 1,
  SAIVY_BANK_FAILURE_STATUS: 0,
  TWENTYFIVE_DOLLAR_STATUS_TRUE: 1
};

export const RIZE_ERROR = {
ALL_FIELDS_ARE_MANDATORY : 'Accept All Terms And Conditions ! To Proceed KYC',
};

export const PROJECT_TYPE_LENGTH = 1;

// Default page for pagination.
export const DEFAULT_PAGE = 1;

// Transaction type.
export const TRANSACTION_TYPE = {
  DEPOSITE: 'positive'
};

export const MAX_CHAR_LENGTH = {
  INPUT_FIELD: 30,
  EMAIL: 100,
  PHONE_NUMBER: 15,
  PASSWORD: 30,
  VALUE_TEN: 10,
  TWO_NUMBERS: 99,
  FOUR_CHARACTERS: 4,
  GLOBAL_CHAR_LIMIT: 50,
  CREATE_ACCOUNT_PHONE_NUMBER: 10,
  FIFTEEN_CHAR: 15,
  TWO_CHAR: 2,
};

// User min age.
export const USER_MIN_AGE = {
  MIN: 18,
  MAX: 100
};

// Location of the object.
export const GENERAL_INDEX = 13;

// Back navigate.
export const BACK_NAVIGATE = -1;

// Project type.
export const PROJECT_TYPE = {
  GENERAL_MAINTENANCE: '4'
};

export const RIZE_DISCLOSER_STATUS = {
  DONE: 1,
  NOT_DONE: 0
};

// Units.
export const UNITS = {
  DOLLAR: '$',
  ZERO: '.00',
  SQFT: ' sqft'
};

// Zillow address filled array.
export const ADDRESS_DATA = {
  ROUTE: 'route',
  STREET: 'street_number',
  ADDRESS: ['route', 'street_number'],
  CITY: 'locality',
  STATE: 'administrative_area_level_1',
  POSTAL_CODE: 'postal_code'
};

export const HIDDEN_PROJECT_TEXT = ['cancelled', 'pause'];
