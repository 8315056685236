import React, { useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';

import bankIcon from '../../Assets/Images/bank-icon.svg';
import SavingModal from '../Shared/Modals/savingModal';
import { ISyncBankAccount } from '../../../Entities/propsTypes';
import { getCookie } from '../../../Container/Services/cookiesServices';
import { PlaidServices } from '../../../Container/Services/plaidServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTHPATHS } from '../../../Routes/Config/paths';
import LoadingButton from '../Shared/Buttons/loadingButton';
import { successToast } from '../Shared/Toastify/toaster';
import { PAYMENT_TYPE } from '../../../Constants/constants';
import { useWebDispatch } from '../../../DataSources/Redux/Store/hooks';
import { getUserProfile } from '../../../DataSources/Redux/Slices/User/userSlice';
import { ProjectRequest } from '../../../Container/Services/projectServices';
import { getProperty } from '../../../DataSources/Redux/Slices/Property/propertySlice';
import { setProjectList } from '../../../DataSources/Redux/Slices/Project/projectListing';
import { TransactionServices } from '../../../Container/Services/transactionServices';
import { SUCCESS_TOAST } from '../../../Constants/messages';

const SyncBankAccount = (props: ISyncBankAccount) => {
    const { plaidModal, setPlaidModal, setLoading, setActive, projectId, fromScreen } = props;
    const dispatch = useWebDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    // Plaid Bank On Success.
    const onSuccessPlaid = (acc: any) => {
        if(acc){
            if(acc?.is_minimum_balance) {
                setPlaidModal(false);
                setButtonLoading(false);
                setActive('');
                if( pathname === AUTHPATHS.DASHBOARD ) {
                    dispatch(getUserProfile());
                    successToast(SUCCESS_TOAST.SUCCESS_BANK_CHANGE);
                } else {
                    const data = {
                        property_id: Number(getCookie('activeProperty')),
                        project_id: Number(projectId),
                        payment_mode: PAYMENT_TYPE.RECURRING,
                      };
                      navigate(AUTHPATHS.ACCOUNT_ELIGIBILITY);
                      TransactionServices.createBankTransaction(data)
                      .then((res: any) => {
                        if (res) {
                          localStorage.setItem('trancId', res?.id);
                          navigate(AUTHPATHS.ACCOUNT_ELIGIBILITY);
                        };
                      });
                }
            } else {
                navigate(AUTHPATHS.ACCOUNT_NOT_ELIGIBLE);
                setButtonLoading(false);
                setActive('');
            }
        };
    };

    // Plaid Props.
    const { open } = usePlaidLink({
        token: getCookie('linkToken'),
        onExit: () => {
            setPlaidModal(false);
            setLoading(false);
            setButtonLoading(false);
            setActive('');
        },
        onSuccess: (publicToken) => {
            if(publicToken) {
                PlaidServices.exchangeToken({public_token: publicToken}).then((res: any) => {
                    if(res) {
                        PlaidServices.retrieveAccount({access_token: res?.access_token}).then((acc: any) => {
                            localStorage.setItem('bankName',acc?.accounts[0].name);
                            localStorage.setItem('processorToken',acc?.processor_token);
                            onSuccessPlaid(acc);
                            dispatch(getProperty()).then((resp: any) => {
                                const id = resp?.payload?.data?.data?.[0]?.id;
                                ProjectRequest.getProjectListing(id)
                                  .then((response) => {
                                    if (response) {
                                      dispatch(setProjectList(response));
                                    }
                                  })
                                  .finally(() => setLoading(false));
                              });
                        });
                    };
                });
            };
        },
    });

    return (
        <SavingModal show={plaidModal} hideCrossIcon={true}>
            <>
                <img src={bankIcon} alt='icon' />
                <div className='modal-title'>Sync Your Bank Account</div>
                <div className='modal-subtitle'>
                    You are requested to sync your bank account for our
                    verification purpose. A minimum balance of
                    <strong>&nbsp;$25.00</strong> in your account is required for your
                    savings plan!
                </div>
                <div className='modal-btns d-grid sync-btn'>
                    <LoadingButton title='Sync My Existing Bank Account' isLoading = {buttonLoading} onClick={() => {
                        open();
                        setButtonLoading(true);
                    }}
                    />
                    <button className='btn btn-danger' onClick={() => {
                        if( pathname === AUTHPATHS.PAYMENT_TYPE ) {
                            navigate(AUTHPATHS.DASHBOARD);
                        }
                        setPlaidModal(false);
                        setActive('');
                    }}
                    >
                        Cancel
                    </button>
                </div>
            </>
        </SavingModal>
    );
};

export default SyncBankAccount;
