import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_MAP_SEARCH_TYPES, RESTRICTED_COUNTRIES } from '../../../../Constants/constants';

interface IGoogleinput {
    onPlaceSelected: (value: any) => void;
    style?:{border: string};
    defaultValue?: string;
    onfocus?: any;
}

const GoogleAddress = (props: IGoogleinput) => {
    const { onPlaceSelected, style, defaultValue, onfocus } = props;

    return (
        <div className="search-location-wrap">
            <Autocomplete
                onClick={() => onfocus()}
                defaultValue={defaultValue}
                style={style}
                apiKey={process.env.REACT_APP_MAP_KEY}
                onPlaceSelected={(place) => onPlaceSelected(place)}
                placeholder="Search Address"
                options={{
                    types: GOOGLE_MAP_SEARCH_TYPES ,
                    componentRestrictions: { country: RESTRICTED_COUNTRIES },
                }}
            />
        </div>
    );
};

export default GoogleAddress;
