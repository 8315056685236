import { AxiosRequestConfig } from 'axios';
import axiosInstance from './axiosInstance';

export const PostRequest = async (url: string, body?: {}, headers?:{}) => {
  const { data, status } = await axiosInstance.post(url, body, headers);
  return { data, status };
};

export const GetRequest = async (url: string, body?: {}) => {
  const { data, status } = await axiosInstance.get(url, body);
  return { data, status };
};

export const DeleteRequest = async (
  url: string,
  id: AxiosRequestConfig | undefined
) => {
  let response;
  await axiosInstance
    .delete(url, id)
    .then((res) => {
      response = res;
    })
    .catch((error) => {
      response = error;
    });
  return response;
};

export const PutRequest = async (url: string, data: {}) => {
  let response;
  await axiosInstance
    .put(url, data)
    .then((res) => {
      response = res;
    })
    .catch((error) => {
      response = error;
    });
  return response;
};

export const PatchRequest = async (url: string, body: {}) => {
  const { data, status } = await axiosInstance.patch(url, body);
  return { data, status };
};
