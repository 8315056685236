import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userProfile } from '../../../../Constants/reducerEnums';

const initialState: any = {
  profile: null,
};

// Reducer for Profile data.
export const userDataSlice = createSlice({
  name: userProfile.getUserProfile,
  initialState,
  reducers: {
    setProfileData: (state, action: PayloadAction<{}>) => {
      state.profile = action.payload; // State function for setting data in reducers.
    },
    resetProfileData: (state) => {
      state.profile = null; // Reset Profile Data.
    },
  },
});

export const { setProfileData, resetProfileData } = userDataSlice.actions;
export default userDataSlice.reducer;
