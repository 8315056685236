import React, { useEffect, useRef, useState } from 'react';
import logoIcon from '../../../Assets/Images/logo.svg';
import homeIcon from '../../../Assets/Images/green-home.svg';
import dropdownIcon from '../../../Assets/Images/dropdown-icon.svg';
import PropertyDetails from '../../Property/propertyDetails';
import PorpertyListing from '../../Property/PropertyListing/propertyListing';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTHPATHS } from '../../../../Routes/Config/paths';
import CommonModal from '../Modals/commonModal';
import Account from '../Account/account';
import { useWebDispatch, useWebSelector } from '../../../../DataSources/Redux/Store/hooks';
import { getUserProfile } from '../../../../DataSources/Redux/Slices/User/userSlice';
import MainLoader from '../Loader/mainLoader';
import SyncBankAccount from '../../Plaid/syncBankAccount';
import { TransactionServices } from '../../../../Container/Services/transactionServices';
import { setTransactionList } from '../../../../DataSources/Redux/Slices/Transactions/transactionSlice';
import { BANK_ACCOUNT_STATUS, DEFAULT_PAGE } from '../../../../Constants/constants';
import CardReissueForm from '../Account/MailingAddress/BankingCards/cardReissueForm';
import { CardServiceProvider } from '../../../../Container/Services/cardServices';
import { setCardDetails } from '../../../../DataSources/Redux/Slices/DebitCard/cardDetails';
import MiniLoader from '../Loader/miniLoader';
import { preventBackBrowsing } from '../../../../Container/Helpers/commonHelpers';

const DashboardNavbar = () => {
  const [hide, setHide] = useState<boolean>(false);// Hide & UnHide selected property on
  const [selectedProperty, setSelectedProperty] = useState<string>('');
  const [active, setActive] = useState<string>('');
  const { activeProperty } = useWebSelector((selected) => selected.propertyData);

  const navigate = useNavigate();
  const dispatch = useWebDispatch();
  const listRef = useRef<any>(null);
  const { pathname } = useLocation();

  const { profile, isLoading } = useWebSelector(
    (userProfile) => userProfile?.profile
  );

  const { property } = useWebSelector((propertyState) => propertyState.property);
  const profileData = profile?.data?.user;
  const defaultSelected = property?.data?.data?.[0]?.address;

  // Account Modal Open and Close.
  const [plaidModal, setPlaidModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const { transactions } = useWebSelector((transactionListing) => transactionListing.transactions);

  // Pagination state for transaction listing.
  const [page, setPage] = useState<number>(transactions?.current_page || DEFAULT_PAGE);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Card reissue modal state and function.
  const [open, setOpen] = useState<boolean>(false);

  // Get profile data from profile api and transactions listing api.
  useEffect(() => {
    dispatch(getUserProfile()).then((res: any) => {
      if(res?.payload) {
        const bankStatus = res?.payload?.data?.user;
        if (bankStatus?.rize_kyc_status === BANK_ACCOUNT_STATUS.ALREADY_CREATED) {
          CardServiceProvider.getDebitCardDetails().then((response) => {
            if( response ) {
              dispatch(setCardDetails(response));
            }
          });
          TransactionServices.getTransactionListing(page).then((response) => {
            if (response) {
              dispatch(setTransactionList(response)); // Setting transaction data in redux store.
            }
          });
        };
      }
    });
  }, [page]);

  useEffect(() => {
    if(pathname === AUTHPATHS.DASHBOARD) {
      preventBackBrowsing();
    }
  },[]);

  useEffect(() => {

    // Bind the event listener.
    document.addEventListener('mousedown', handleOutsideClicks);
      return () => {
        // Unbind the event listener on clean up.
        document.removeEventListener('mousedown', handleOutsideClicks);
    };

  }, [hide]);


  // Function for handle out side clicks from the filter dropdown.
  const handleOutsideClicks = (event: any) => {
    if(!hide && listRef?.current && !listRef?.current?.contains(event.target)) {
      setHide(true);
    };
  };

  return (
    <>
      {isLoading && <MainLoader />}
        <div className='main-header bg-dark' data-testid='dashboard-navbar' ref={listRef}>
          <div className='header-row'>
            <div className='container'>
              <div className='header-logo'>
                <img src={logoIcon} alt='logo' />
              </div>
              <div
                className='header-property-address-row d-none d-md-block'
                onClick={() => setHide(!hide)}
              >
                <div className='header-property-col'>
                  <div className='prop-icon'>
                    <img src={homeIcon} alt='icon' />
                  </div>
                  <div className='prop-address'>
                    {activeProperty?.address || defaultSelected}
                  </div>
                  <div className='dropdown-icon'>
                    <img src={dropdownIcon} alt='icon' />
                  </div>
                </div>
                {/* Property listing */}
                <PorpertyListing
                  setHide={setHide}
                  hidden={hide}
                  setSelectedProperty={setSelectedProperty}
                />
              </div>
              <div className='profile-dropdown' onClick={handleShow}>
                <div className='profile-name'>
                  {profileData ? `${profileData?.first_name} ${profileData?.last_name}`: <MiniLoader/>}
                  <img src={dropdownIcon} alt='icon' />
                </div>
              </div>
            </div>
            <div className='header-property-address-row container d-md-none'
                onClick={() => setHide(!hide)}
              >
                <div className='header-property-col'>
                  <div className='prop-icon'>
                    <img src={homeIcon} alt='icon' />
                  </div>
                  <div className='prop-address'>
                    {activeProperty?.address || defaultSelected}
                  </div>
                  <div className='dropdown-icon'>
                    <img src={dropdownIcon} alt='propicon' />
                  </div>
                </div>
                {/* Property listing */}
                <PorpertyListing
                  setSelectedProperty={setSelectedProperty}
                  setHide={setHide}
                  hidden={hide}
                />
              </div>
          </div>
          <div className='header-bottom'>
            <div className='container'>
              {/* property details popup */}
              <PropertyDetails />
              <div className='header-saving-col'>
                <div className='saving-value'>$0.00</div>
                <div className='saving-text'>Your Total Savings</div>
              </div>
              <div className='project-btn'>
              {/* Checking the length of project listing for hide and unhide create project button.
               * 0 is the length of project list when no project is added for a property
               */}
                <button
                  className='btn btn-outline'
                  onClick={() => navigate(AUTHPATHS.ADD_PROJECT)}
                >
                  Create Project
                </button>
              </div>
            </div>
          </div>
        </div>
      <CommonModal
        show={show}
        onShow={() => handleShow()}
        onHide={() => handleClose()}
      >
        <Account
          setActive={setActive}
          active={active}
          close={() => handleClose()}
          profileData={profileData}
          setPlaidModal = {setPlaidModal}
          setPage={setPage}
          page={page}
          setOpen={setOpen}
          open={open}
        />
      </CommonModal>
      <SyncBankAccount
        plaidModal={plaidModal}
        setPlaidModal={setPlaidModal}
        setLoading = {setLoading}
        loading = {loading}
        setActive = {setActive}
        active = {active}
        bankStatus = {profileData?.rize_account_status}
      />
      <CommonModal
        dialogueClassName='modal-card-reissue'
        onHide={() => setOpen(true)}
        show={open}
        onShow={() => setOpen(true)}
        centered={true}
        onModalClose = {() => setOpen(false)}
      >
        <CardReissueForm onModalClose={() => setOpen(false)}/>
      </CommonModal>
    </>
  );
};

export default DashboardNavbar;
