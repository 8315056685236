import React from 'react';
import { Spinner } from 'react-bootstrap';

const MainLoader = () => {
    return (
        <div className="loader-conatiner">
            <Spinner animation='border' variant='success' />
        </div>
    );
};

export default MainLoader;
