import moment from 'moment';
import { NavigateFunction } from 'react-router-dom';
import {
   buttonName,
   NUMBERS,
   ASSCICODE,
   DEFAULT_COUNTRY_CODE,
   DEFAULT_VALUE, ERROR_KEY_LENGTH,
   BACK_BROWSING,
   LATER_BUTTON,
   LOGOUT_BUTTON,
   STEP_FILLED_STATUS,
   CURRENCY_FORMATER,
   BUTTON_STATUS,
   MIN_TENURE,
   DEFAULT_PAGE,
   USER_MIN_AGE,
   ADDRESS_DATA,
  } from '../../Constants/constants';
import { CONFIRM_MODAL_CONSTANTS } from '../../Constants/modalConstants';
import { API_ERROR_KEYS } from '../../Constants/errorKeys';
import { IforgotPassword, ILoginForm, IRegisterForm } from '../../Entities/InputInterface';
import { IErrorKeyTypes, UpdateProfileType } from '../../Entities/propsTypes';
import { AUTHPATHS, COMMONPATHS } from '../../Routes/Config/paths';
import { getCookie } from '../Services/cookiesServices';
// Function to get US- Formatted number.
/** after matching with regex... I got..
 * 0: "8791155263"
 * 1: "879"
 * 2: "115"
 * 3: "5263"
 * Using @return to get US- Formatted number
 */
export const formatNumber = (value: string | null) => {
  const USNumber = value?.match(/(\d{3})(\d{3})(\d{4})/);
  return `+1 ${USNumber?.[NUMBERS.ONE]} ${USNumber?.[NUMBERS.TWO]} ${USNumber?.[NUMBERS.THREE]
    }`;
};

// Function for getting error and common className.
export const getErrorClassName = (
  touched: boolean | undefined | string,
  errors: string | undefined
) => {
  return `form-control ${touched && errors ? 'invalid' : ''}`;
};

export const getButtonLabel = (pathname: string, userType: boolean) => {
  let buttonLabel = buttonName.login;
  if ([COMMONPATHS.LOGIN, COMMONPATHS.DEFAULT].includes((pathname))) {
    buttonLabel = buttonName.register;
  } else if (LOGOUT_BUTTON.includes(pathname)) {
    if(userType) {
      buttonLabel = buttonName.logout;
    } else {
      buttonLabel = buttonName.cancel;
    }
  }else if(LATER_BUTTON.includes(pathname)){
    buttonLabel = buttonName.later;
  }
  // TODO: Add more conditions here.
  return buttonLabel;
};

// Function for common error messages.
export const getToastError = (errorKey: string) => {
  const apiKey = API_ERROR_KEYS as IErrorKeyTypes;
  return apiKey[errorKey];
};


export const checkNonNumericDecimalChars = (e: {
  key: string | undefined;
  keyCode: number | undefined;
  preventDefault: () => unknown;
},
  checkNonDecimal: boolean) => {
  let checkIfChar = false;
  let operatorcheck: Array<string>;
  if (checkNonDecimal) {
    operatorcheck = ['e', 'E', '.', '+', '-'];
  } else {
    operatorcheck = ['e', 'E', '+', '-'];
  }
  if (e.key !== undefined) {
    checkIfChar = operatorcheck.includes(e.key);
  } else if (e.keyCode !== undefined) {
    checkIfChar = [
      ASSCICODE.SIXTY_NINE,
      ASSCICODE.ONE_HUNDRED_EIGHTY_SEVEN,
      ASSCICODE.ONE_HUNDRED_NINTY,
      ASSCICODE.ONE_HUNDRED_EIGHTY_NINE,
    ].includes(e.keyCode);
  }
  return checkIfChar && e.preventDefault();
};

// Prevent back browser button.
export const noBackBrowsing = (pathname: string) => {
  if (BACK_BROWSING.includes(pathname)) {
    window.history.forward();
  };
};

export const changePhoneNumberData = (values: IforgotPassword) => {
  return {
    phone_number: values?.phone_number,
    country_code: values?.country_code || `+${DEFAULT_COUNTRY_CODE}`
  };
};

export const defaultData = (value: string) =>{
  return value ? value : DEFAULT_VALUE;
};

// Count words length.
export const countWords = (val: string) => {
  const arr = val.split(' ');
  return arr.filter((word: string) => word !== '')?.length;
};

// Scroll to the error Input.
export const scrollToErrors = (errors: any) => {
  const errorKeys = Object.keys(errors);
  if (errorKeys.length > ERROR_KEY_LENGTH) {
    const errorElement = document.getElementsByName(errorKeys[ERROR_KEY_LENGTH])[ERROR_KEY_LENGTH];
    errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    errorElement.focus({ preventScroll: true });
  };
};

// Get estimates Tab name.
export const getEstimatesTabName = (val: string | null) => {
  if(val === 'Bathroom Remodel') {
    return 'Bathroom Remodelling';
  } else if(val === 'Kitchen Remodel') {
    return 'Kitchen Remodelling';
  }
  return val;
};

export const currencyFormat = (val: string | undefined) => {
  const formattingOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };
 return Number(val).toLocaleString('en-US', formattingOptions);
};

// Active link on profile view
export const getActiveLink = (active: string) => {
  let linkClass = 'profile-link-btn';
  if(active === '1') {
    linkClass = `${linkClass} selected`;
  }
  return linkClass;
};

// Data Handle Function for Estimates.
export const handleEstimateData = (
  setActive: (val: string) => void,
  setData: any,
  activeCard: string,
  id: string,
  costEstimate: string,
  type: string
) => {
  setActive(activeCard);
  setData({ estimate_id: id, estimated_cost: costEstimate, project_type_id: type });
};

// Range Slider Helpers.
export const rangeData = (data: any) => {
  let min = 0; // Minimum Sider Value
  let max = 0; // Maximum Slider Value
  let step = 0; // Steps of slider
  let defaultTenure = 0; // Default Emi Tenure

  if(data) {
    min = data?.[0].interval;
    max = data[data?.length- 1]?.interval;
    step = min;
    defaultTenure = data?.[0].amount || MIN_TENURE;
  }

  return {
    min, max, step, defaultTenure
  };

};

// Error For Custom Budget.
export const budgetError = () => {
  return false;
};

// Function for formatting the input value in USD format.
export const currencyFormater = (amount: number | string) => {
  if (amount > CURRENCY_FORMATER.DEFAULT_AMOUNT_VALUE) {
    const options = {
      minimumFractionDigits: CURRENCY_FORMATER.MINIMUM_FRACTION,
    };
    return `$${Number(amount).toLocaleString('en', options)}`;
  } else {
    return amount;
  };
};

// Date formater.
export const dateFormater = (val: string, format: string) => {
  const date = moment(new Date(val), 'YYYY-MM-DD'); // Date format using moment in YYYY-MM-DD.
  return date.format(format); // 'LL' use for convert date from 2014-07-09 to 'June 9 2014'
};

// Hide/show password field value.
export const hideShowPassword = (value: string, setValue: (val: string) => void) => {
  (value === 'password') ? setValue('text') : setValue('password');
};

// Function for handle turnary operator.
export const getOptionData = (
  data: any,
  Argument1: string | undefined,
  Argument2: string | undefined) => {
    return data ? Argument1 : Argument2;
};

// Function for getting default page for pagination.
export const getPage = (value: number | undefined) => {
  return value || DEFAULT_PAGE;
};

export const urlNavigate = (
  response: any,
  url: string,
  state: any,
  navigate: NavigateFunction,
  setOtp?: (val: string) => void
) => {
  if (response) {
    navigate(url, {
      state,
    });
  } else {
    setOtp?.('');
  }
  return null;
};

// Update profile data manipulation according to user.
export const updateProfilePayload = (data: UpdateProfileType, profileData: UpdateProfileType) => {
  const dataPayload = {};
  if(data.firstName !== profileData.firstName) {
    Object.assign(dataPayload, {firstName : data.firstName});
  }
  if(data.lastName !== profileData.lastName) {
    Object.assign(dataPayload, {lastName : data.lastName});
  }
  if(data.email !== profileData.email) {
    Object.assign(dataPayload, {email : data.email});
  }
  return dataPayload;
};

// Function for get 18 years back date from today.
export const getMaxDate = () => {
  const date = new Date();
  const pastYear = date.getFullYear() - USER_MIN_AGE.MIN;
  date.setFullYear(pastYear);
  return date;
};

export const getMinDate = () => {
  const date = new Date();
  const pastYear = date.getFullYear() - USER_MIN_AGE.MAX;
  date.setFullYear(pastYear);
  return date;
};

export const getActiveProperty = (id: string) => {
  return id ? id : getCookie('activeProperty');
};

// Function for scroll move to top.
export const scrollToTop = () => {
  const elem: any = document.getElementsByClassName('modal-body');
  elem.scrollTop = 0;
};

// Stop back browsing.
export const preventBackBrowsing = () => {
  window.history.pushState(null, '', window.location.href);
    window.onpopstate = function() {
      window.history.go(1);
    };
};
