//state dropdown
export const US_STATE_DROPDOWN = [
  { value: '', label: 'Select State' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecti Cut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
];

// KYC Dropdown List
export const KYC_DROPDOWN = [
  { value: null, label: 'Select Document' },
  { value: 'license', label: 'License' },
  { value: 'contract', label: 'Contract' },
  { value: 'other', label: 'Other' },
  { value: 'utility', label: 'Utility' },
  { value: 'passport', label: 'Passport' },
];

export const PROJECT_MODEL_TYPE = {
  KITCHEN: 'Kitchen Remodel',
  BATHROOM: 'Bathroom Remodel',
  FLOOR: 'Floor Replacement',
  GENERAL: 'General Maintenance',
};

// Tenure Dropdown Value and Labels.
export const TENURE_DROPDOWN_OPTIONS = [
  { value: '1', label: '1st of every month' },
  { value: '2', label: '2nd of every month' },
  { value: '3', label: '3rd of every month' },
  { value: '4', label: '4th of every month' },
  { value: '5', label: '5th of every month' },
  { value: '6', label: '6th of every month' },
  { value: '7', label: '7th of every month' },
  { value: '8', label: '8th of every month' },
  { value: '9', label: '9th of every month' },
  { value: '10', label: '10th of every month' },
  { value: '11', label: '11th of every month' },
  { value: '12', label: '12th of every month' },
  { value: '13', label: '13th of every month' },
  { value: '14', label: '14th of every month' },
  { value: '15', label: '15th of every month' },
  { value: '16', label: '16th of every month' },
  { value: '17', label: '17th of every month' },
  { value: '18', label: '18th of every month' },
  { value: '19', label: '19th of every month' },
  { value: '20', label: '20th of every month' },
  { value: '21', label: '21st of every month' },
  { value: '22', label: '22nd of every month' },
  { value: '23', label: '23rd of every month' },
  { value: '24', label: '24th of every month' },
  { value: '25', label: '25th of every month' },
  { value: '26', label: '26th of every month' },
  { value: '27', label: '27th of every month' },
  { value: '28', label: '28th of every month' },
];
