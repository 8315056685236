import { USER_API_CONSTANTS } from '../../Constants/apiConstants';
import { UpdateProfilePayloadType } from '../../Entities/userStoreTypes';
import { RequestService } from './requestServices';

export class UserRequest extends RequestService {
  getUserProfile = async () => {
    return this.get(USER_API_CONSTANTS.USER_PROFILE);
  };

  // Profile update.
  profileUpdate = async (data: UpdateProfilePayloadType) => {
    return this.patch(USER_API_CONSTANTS.UPDATE_PROFILE, data);
  };
}
