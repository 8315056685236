import { API_CONSTANTS } from '../../Constants/apiConstants';
import { IAddAddressTypes } from '../../Entities/propertyTypes';
import { RequestService } from './requestServices';

export class PropertyRequest extends RequestService {
  getProperty = async (id: string) => {
    return this.get(`${API_CONSTANTS.PROPERTY_DETAILS}/${id}`);
  };

  addProperty = async (data: IAddAddressTypes) => {
    return this.post(API_CONSTANTS.ADD_PROPERTY, data);
  };

  getZillowData = async(data: {address: string, unit: number}) => {
    return this.post(API_CONSTANTS.EXTERNAL_PROPERTY_DETAILS, data);
  };
}
