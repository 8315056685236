import { Axios } from 'axios';
import { Interceptors } from './interceptor';

export class RequestService extends Axios {
    constructor() {
        super({
            baseURL: process.env.REACT_APP_API_URL
        });
        this.interceptors.request.use(Interceptors.requestInterceptor);
        this.interceptors.response.use(Interceptors.responseInterceptor);
    }
};
