
export const reducerEnums = {
  register: 'saivy/register',
  login: 'saivy/login',
  otpVerify: 'saivy/otpVerify',
  resendOtp: 'saivy/resendOtp',
  changePhoneNumber: 'saivy/changePhoneNumber',
  auth: 'saivy/auth',
};

export const property = {
  getProperty: 'saivy/getProperty',
  property: 'saivy/property',
  addProperty: 'saivy/addPropertyData',
  getCostEstimates: 'saivy/getCostEstimates',
  costEstimatesPost: 'saivy/postCostEstimates',
  getModelType: 'saivy/getModelType',
  customEstimatation: 'saivy/customEstimatation',
  getPropertyData: 'saivy/getPropertyData',
  getZillowData: 'saivy/getZillowData'

};

export const profileEnums = {
  getProfile: 'saivy/getProfile',
  userProfile: 'saivy/userProfile',
  verifyEmail: 'saivy/verifyEmail',
};

export const project = {
  getModelType: 'saivy/getModelType',
  createProject: 'saivy/createProject',
  getProjectBasedProperties: 'saivy/getProjectBasedProperties',
  property: 'saivy/property',
  projectListing: 'saivy/project',
  projectFilter: 'saivy/projectFilter',
  customSegregation: 'saivy/customSegregation'
};

export const zillowProperty = {
  getZillowData: 'saivy/getZillowData',
  zillowProperty: 'saivy/zillowProperty',
};

export const bankDetails = {
  createAccount: 'saivy/createAccount',
  riseDisclosure: 'saivy/riseDisclosure',
  acknowledge: 'saivy/acknowledge',
  kycDocumentation: 'saivy/kyc',
  tenure: 'saivy/tenure/selection',
  transactions: 'saivy/transactions'
};

export const userProfile = {
  getUserProfile : 'siavy/getUserProfile'
};

export const debitCard ={
  cardDetails: 'saivy/cardDetails',
};

