import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { COMMONPATHS } from './Config/paths';
import AuthWrapper from './Wrappers/authRoutes';
import { getCookie } from '../Container/Services/cookiesServices';

const Login = React.lazy(() => import('../UI/Components/User/login'));
const Register = React.lazy(() => import('../UI/Components/User/register'));
const HtmlTest = React.lazy(() => import('../UI/Components/html'));
const ForgotPassword = React.lazy(
 () => import('../UI/Components/User/forgotPassword')
);
const TermsAndConditions = React.lazy(
 () => import('../UI/Components/TermsAndPolicies/terms')
);
const PrivacyAndPolicy = React.lazy(
 () => import('../UI/Components/TermsAndPolicies/privacyPolicies')
);
const VerifyOtp = React.lazy(() => import('../UI/Components/User/verifyOtp'));
const VerifyEmail = React.lazy(() => import('../UI/Components/User/verifyEmail'));
const ResetPassword = React.lazy(() => import('../UI/Components/User/addNewPassword'));

const AuthRoutes = () => {
 const location = useLocation();
 const token = getCookie('access_token');
 if (token) {
  return <AuthWrapper />;
 } else {
  return (
   <Navigate to={COMMONPATHS.DEFAULT} state={{ path: location.pathname }} />
  );
 }
};

const PublicRoutes = [
 { path: COMMONPATHS.APP, element: <AuthRoutes /> },
 { path: COMMONPATHS.LOGIN, element: <Login /> },
 { path: COMMONPATHS.REGISTER, element: <Register /> },
 { path: COMMONPATHS.DEFAULT, element: <Login /> }, // Default path for welcome will be added in future.
 { path: COMMONPATHS.html, element: <HtmlTest /> },
 { path: COMMONPATHS.FORGOT_PASSWORD, element: <ForgotPassword /> },
 { path: COMMONPATHS.TERMS_AND_CONDITIONS, element: <TermsAndConditions /> },
 { path: COMMONPATHS.PRIVACY_POLICIES, element: <PrivacyAndPolicy /> },
 { path: COMMONPATHS.VERIFY_OTP, exact: true, element: <VerifyOtp /> },
 { path: COMMONPATHS.VERIFY_EMAIL, element : <VerifyEmail/>},
 { path: COMMONPATHS.RESET_PASSWORD, element: <ResetPassword/>}
];

export default PublicRoutes;
