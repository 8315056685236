import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_CONSTANTS } from '../../../../Constants/apiConstants';
import { project } from '../../../../Constants/reducerEnums';
import { IProjectStoreType, IPropertyId } from '../../../../Entities/projectTypes';
import { successToast } from '../../../../UI/Components/Shared/Toastify/toaster';
import { GetRequest, PostRequest } from '../../../ApiServices/request';

const initialState: IProjectStoreType = {
    isLoading: false,
    project: null,
    isError: false,
    isSuccess: false,
    message: null,
    remodellingType: null,
    projectCreatedData: null,
};

// Project Model type.
export const getProjectModelType = createAsyncThunk(
    project.getModelType,
    async (_data, thunkAPI) => {
        try {
            const { data, status } = await GetRequest(
                API_CONSTANTS.REMODELLING_TYPE
            );
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const createProject = createAsyncThunk(
    project.createProject,
    async (projectData: {}, thunkAPI) => {
        try {
            const { data, status } = await PostRequest(API_CONSTANTS.CREATE_PROJECT, projectData);
            successToast(data?.message);
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getProjectListBasedProperty = createAsyncThunk(
    project.getProjectBasedProperties,
    async (property:IPropertyId, thunkAPI) => {
        try {
            const { data, status } = await GetRequest(
                `${API_CONSTANTS.PROJECT_LIST_PROPERTY}/${property?.property_id}/project/list`
            );
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const projectSlice = createSlice({
    name: project.property,
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjectModelType.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProjectModelType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.remodellingType = action.payload;
                state.project = null;
            })
            .addCase(getProjectModelType.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.remodellingType = null;
                state.project = null;
            })
            .addCase(getProjectListBasedProperty.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProjectListBasedProperty.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.remodellingType = null;
                state.project = action.payload;
            })
            .addCase(getProjectListBasedProperty.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.remodellingType = null;
                state.project = null;
            })
            .addCase(createProject.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.remodellingType = null;
                state.project = null;
                state.projectCreatedData = action.payload;
            })
            .addCase(createProject.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.remodellingType = null;
                state.project = null;
                state.projectCreatedData = null;
            });
    },
});

export const { reset } = projectSlice.actions;
export default projectSlice.reducer;
