import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PROPERTY_LENGTH } from '../../../../Constants/constants';
import { setCookie } from '../../../../Container/Services/cookiesServices';
import { IPropertyStoreType } from '../../../../Entities/authStoreTypes';
import { API_CONSTANTS } from '../../../../Constants/apiConstants';
import { property } from '../../../../Constants/reducerEnums';

import { GetRequest, PatchRequest, PostRequest } from '../../../ApiServices/request';
import { ICostEstimationPayload } from '../../../../Entities/projectTypes';

const initialState: IPropertyStoreType = {
    isLoading: false,
    property: null,
    isError: false,
    isSuccess: false,
    message: null,
    estimation: null,
    projectRemodellingData: null
};

export const getProperty = createAsyncThunk(
    property.getProperty,
    async (_data, thunkAPI) => {
        try {
            const { data, status } = await GetRequest(API_CONSTANTS.PROPERTY_LISTING);
            const propertyData = data?.data?.[PROPERTY_LENGTH.MIN];
            setCookie('activeProperty', propertyData?.id);
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const addPropertyDetailsApi = createAsyncThunk(
    property.addProperty,
    async (propertiesData: {}, thunkAPI) => {
        try {
            const { data, status } = await PostRequest(API_CONSTANTS.ADD_PROPERTY, propertiesData);
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const costEstimates = createAsyncThunk(
    property.getCostEstimates,
    async (estimatesData: {}, thunkAPI) => {
        try {
            const { data, status } = await PatchRequest(API_CONSTANTS.COST_ESTIMATES,estimatesData);
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);
export const customEstimatation = createAsyncThunk(
    property.customEstimatation,
    async (estimatesData: {}, thunkAPI) => {
        try {
            const { data, status } = await PatchRequest(API_CONSTANTS.COST_ESTIMATES,estimatesData);
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const postCostEstimates = createAsyncThunk(
    property.costEstimatesPost,
    async (estimatesData: ICostEstimationPayload, thunkAPI) => {
        try {
            const { data, status } = await GetRequest(`${API_CONSTANTS.COST_ESTIMATES_POST}/${estimatesData?.project_id}`);
            return { data, status };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const propertySlice = createSlice({
    name: property.property,
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProperty.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getProperty.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.property = action.payload;
            })
            .addCase(getProperty.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.property = null;
            })
            .addCase(addPropertyDetailsApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addPropertyDetailsApi.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.property = action.payload;
            })
            .addCase(addPropertyDetailsApi.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.property = null;
            })
            .addCase(costEstimates.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(costEstimates.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.property = action.payload;
            })
            .addCase(costEstimates.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.property = null;
            })
            .addCase(postCostEstimates.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(postCostEstimates.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.projectRemodellingData = action.payload;
            })
            .addCase(postCostEstimates.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.projectRemodellingData = null;
            })
            .addCase(customEstimatation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(customEstimatation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.estimation = action.payload;
            })
            .addCase(customEstimatation.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.property = null;
            });
    },
});

export const { reset } = propertySlice.actions;
export default propertySlice.reducer;
