import { TransactionRequest } from '../../DataSources/ApiServices/transcationRequests';
import { ITranscationTypes, PauseTransactionType, SkipTransactionType } from '../../Entities/bankTypes';

export class TransactionServices {

  private static readonly transactionRequest: TransactionRequest = new TransactionRequest();

  // Create transcation for one time deposit.
  static createBankTransaction = async(data: ITranscationTypes) => {
    return this.transactionRequest.createTranscation(data);
  };

  // Cancel transaction.
  static cancelProjectTransaction = async(data: {transaction_id: string, password_confirm: string}) => {
    return this.transactionRequest.cancelTransaction(data);
  };

  // Pause transaction.
  static pauseProjectTransaction = async(data: PauseTransactionType) => {
    return this.transactionRequest.pauseTransaction(data);
  };

  // Skip transaction.
  static skipProjectTransaction = async(data: SkipTransactionType) => {
    return this.transactionRequest.skipTransaction(data);
  };

  // Transaction listing.
  static getTransactionListing = async(page: number) => {
    return this.transactionRequest.getTransactions(page);
  };

  // Transaction listing for particular project.
  static getProjectTransactionService = async(data: {projectId: string | undefined, page: number}) => {

    // Managing data format for api payload.
    const projectTransactionPayload = {
      project_id: data.projectId,
      page: data.page
    };

    return this.transactionRequest.getProjectTransaction(projectTransactionPayload);
  };

};
