import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { PAGINATION_RANGE } from '../../../../Constants/constants';
import { IPaginationType } from '../../../../Entities/propsTypes';

const ListingPagination = (props: IPaginationType) => {
  const { totalPage, setPage, page } = props;

  // Get Current Page Range.
  const [range, setRange] = useState<number>(page || 1);

  // Page Range For Pagination.
  const pageRange = Array.from(Array(totalPage + PAGINATION_RANGE.EXCEED_RANGE).keys()).slice(1);

  return (
    <Pagination>
      <Pagination.Prev
        disabled={range <= PAGINATION_RANGE.EXCEED_RANGE}
        onClick={() => {
          setPage(range - PAGINATION_RANGE.EXCEED_RANGE);
          setRange(range - PAGINATION_RANGE.EXCEED_RANGE);
        }}
      />
      {pageRange &&
        pageRange.map((value, index) => {
          return (
            <Pagination.Item
              active={range === (index + PAGINATION_RANGE.EXCEED_RANGE)}
              key={index}
              onClick={() => {
                setRange(index + PAGINATION_RANGE.EXCEED_RANGE);
                setPage(index + PAGINATION_RANGE.EXCEED_RANGE);
              }}
            >
              {value}
            </Pagination.Item>
          );
        })}
      <Pagination.Next
        disabled={range === pageRange?.length}
        onClick={() => {
          setPage(range + PAGINATION_RANGE.EXCEED_RANGE);
          setRange(range + PAGINATION_RANGE.EXCEED_RANGE);
        }}
      />
    </Pagination>
  );
};

export default ListingPagination;
