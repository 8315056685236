export const API_ERROR_KEYS = {
  AUTHORIZATION_TOKEN_ERROR: 'Please Login',
  INVALID_TOKEN: 'Invalid Token',
  EXPIRED_TOKEN: 'Token has expired.',
  UNDEFINED_ERROR: 'UNDEFINED_ERROR',
  METHOD_NOT_ALLOWED: 'Method not allowed',
  HTTP_NOT_FOUND: 'HTTP_NOT_FOUND',
  FORBIDDEN: 'FORBIDDEN',
  CAPTCHA_ERROR: 'CAPTCHA_ERROR',
  USER_PHONE_NUMBER_NOT_FOUND: 'This phone number is not registered with us',
  LOGIN_CREDENTIALS_INVALID: 'Invalid credentials',
  TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
  INCORRECT_OTP_ERROR: 'Incorrect OTP. Please try again',
  OTP_EXPIRED: 'OTP Expired. Please resend OTP.',
  PHONE_NOT_VERIFIED: 'Phone number is not verified',
  PROPERTY_TYPE_DATA_NOT_FOUND: 'PROPERTY_TYPE_DATA_NOT_FOUND',
  PROPERTY_ALREADY_EXIST: 'Property already exists.',
  PROPERTY_LIST_DATA_NOT_FOUND: 'PROPERTY_LIST_DATA_NOT_FOUND',
  INTERNAL_SERVER_ERROR: 'Internal Server Error',
  TWILIO_CREATE_RECORD_ERROR: 'TWILIO_CREATE_RECORD_ERROR',
  REMODELLING_DATA_NOT_FOUND: 'REMODELLING_DATA_NOT_FOUND',
  USER_NOT_FOUND: 'User not found',
  PHONE_NUMBER_ALREADY_EXIST: 'The phone number is already registered with us',
  EMAIL_ALREADY_EXIST: 'The email already registered with us',
  UNABLE_TO_PROCESSED: 'Unable to be processed with this request',
  DATA_NOT_FOUND: 'Data not found',
  TOO_MANY_ATTEMPTS: 'Too many attempts',
  ZILLOW_DATA_NOT_FOUND:
    'Address details could not be fetched. Please complete the form to proceed',
  COULD_NOT_CREATE_CUSTOMER: 'Email must be unique for primary customers',
  CUSTOMER_DAILY_EXTERNAL_TRANSFER_AMOUNT_HAS_BEEN_REACHED:
    'You have been reached your daily external transfer limit',
  MALFORMED_UPDATE_PARAMETER:
    'Enter valid social security number or address details',
  AT_LEAST_ONE_REMODELLING_SELECTION_NEEDED:
    'At least one selection is required',
  VERIFY_MINIMUM_BALANCE:
    'You should have a minimum balance of $25 in his/her primary bank account.',
  CARD_MAILING_ADDRESS_ALREADY_ADDED: 'Mailing address already added',
  CAP_AMOUNT_EXCEEDED:
    'You have exceed the limits of savings for this project type',
  SYNC_ACCOUNT_FIRST: 'Please sync primary bank account',
  CUSTOMER_MAXIMUM_REACHED: 'Please try after some time',
  TOKEN_NOT_CREATED: 'Token for reset password was not created',
  INCORRECT_TOKEN_ERROR: 'Token for reset password is not correct',
  NEW_PASSWORD_CAN_NOT_SAME_AS_CURRENT_PASSWORD: 'Your new password cannot be same as your current password!',
  INCORRECT_EXISTING_PASSWORD: 'Current password is incorrect!',
  CARD_MAILING_ADDRESS_NOT_CREATED: 'Please add card mailling address first.',
  INVALID_DEBIT_CARD_STATUS_FOR_REISSUE: 'Invalid reissue reason.',
  COULD_NOT_CREATE_SYNTHETIC_ACCOUNT: 'Your synthetic account could not create.',
  USER_ENTERED_INCORRECT_PASSWORD: 'You have entered incorrect password.',
  DOB_MUST_BE_EIGHTEEN_YEAR : 'Your Age must be at least 18 years Old.',
  CUSTOMER_ALREADY_IN_USE: 'Customer already in use.'
};
