import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { bankDetails } from '../../../../Constants/reducerEnums';

const initialState: any = {
  data: null,
  message: null,
};

// Reducer for property data.
export const tenureSlice = createSlice({
  name: bankDetails.tenure,
  initialState,
  reducers: {
    setTenureData: (state, action: PayloadAction<{}>) => {
      state.data = action.payload; // State function for setting data in reducers.
      state.error = false;
    },
    resetTenureData: (state) => {
      state.data = null; // Reset Tenure Data.
      state.error = false;
    },
  },
});

export const { setTenureData, resetTenureData } = tenureSlice.actions;
export default tenureSlice.reducer;
