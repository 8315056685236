import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
// Local Import
import FormikController from '../../Formik/formikController';
import { getErrorClassName } from '../../../../../Container/Helpers/commonHelpers';
import { mailingAddressInitialValues } from '../../../../../Constants/initialValues';
import { mailingAddressValidations } from '../../../../../Constants/validationSchema';
import GoogleAddress from '../../InputFields/googleAddress';
import Dropdown from '../../Dropdown/dropdown';
import { US_STATE_DROPDOWN } from '../../../../../Constants/dropDownConstants';
import CardIssuedModal from './BankingCards/cardReissueForm';
import ConfirmAddressModal from '../../Modals/confirmAddressModal';
import LoadingButton from '../../Buttons/loadingButton';
import { getGoogleAddress } from '../../../../../Container/Helpers/googleAddressHelper';

const MailingAddress = (props: any) => {
  const [show, setShow] = useState<boolean>(false);
  const [cardDeliverAddress, setCardDeliverAddress] = useState<string>();
  const formRef = useRef<any>();
  const [ selectedState, setSelectedState ] = useState<{ label: string, value: string}>({label: '', value:''});

  // Handle Google Address Place Selection
  const handlePlaceSelected = (val: any, setFieldValue: any) => {
    setCardDeliverAddress(val?.formatted_address);
    const { state, address1, city, postalCode } = getGoogleAddress(val);
    setSelectedState(state);
    setFieldValue('city', city);
    setFieldValue('address_line_1', address1);
    setFieldValue('postal_code', postalCode);
  };

  // Handle US State DropDown
  const handleUsStateDropDown = (e: { value: string }, setFieldValue: any) => {
    setFieldValue('state', e.value);
  };

  // Modal open and close functions.
  const onModalOpen = () => setShow(true);

  return (
    <>
      <div className='modal-card'>
        <div className='modal-title'>Mailing Address</div>
        <div className='modal-subtitle'>
          Please enter your mailing address so we can send the physical card on
          the address.
        </div>
        <div className='profile-form'>
          <Formik
            initialValues={mailingAddressInitialValues}
            validationSchema={mailingAddressValidations}
            onSubmit={props.onSubmit}
            innerRef={formRef}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ touched, errors, setFieldValue, values }) => (
              <Form>
                <div className='form-col'>
                  <FormikController
                    name='postal_code'
                    req={true}
                    hidden={false}
                    className={getErrorClassName(
                      touched.postal_code,
                      errors.postal_code
                    )}
                    label='Zip Code'
                    type='text'
                    control='input'
                  />
                </div>
                <div className='form-col'>
                  <label className='form-label'>
                    State<span className='red-star'>*</span>
                  </label>
                  <div
                    className='state-dropdown'
                  >
                    <Dropdown
                      options={US_STATE_DROPDOWN}
                      defaultValue={selectedState}
                      value={selectedState}
                      onChange={(e: any) =>
                        handleUsStateDropDown(e, setFieldValue)
                      }
                      placeholder='Select'
                      isSearchable={true}
                      className={`react-select-dropdown-custom ${
                        touched.state && errors.state ? 'invalid' : ''
                      }`}
                    />
                    {touched.address_line_1 && errors.address_line_1 ? (
                      <div className='validation-error'>Required!</div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='form-col'>
                  <FormikController
                    name='city'
                    req={true}
                    hidden={false}
                    className={getErrorClassName(touched.city, errors.city)}
                    label='City'
                    type='text'
                    control='input'
                  />
                </div>
                <div className='form-col'>
                  <label className='form-label'>
                    Address Line 1<span className='red-star'>*</span>
                  </label>
                  <div
                    className={`account-address ${
                      touched.address_line_1 && errors.address_line_1
                        ? 'invalid'
                        : ''
                    }`}
                  >
                    <GoogleAddress
                      onPlaceSelected={(place: any) =>
                        handlePlaceSelected(place, setFieldValue)
                      }
                    />
                    {touched.address_line_1 && errors.address_line_1 ? (
                      <div className='validation-error'>Required!</div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='form-col'>
                  <FormikController
                    type='text'
                    control='input'
                    name='address_line_2'
                    req={false}
                    hidden={false}
                    className='form-control'
                    label='Address Line 2'
                  />
                </div>
                <div>
                  <LoadingButton
                    icon={true}
                    title='Confirm Address'
                    className='btn btn-primary w-100'
                    isLoading={props.loading}
                  />
                </div>
              </Form>
            )}
          </Formik>
          <button className='btn btn-danger w-100 mt-3' onClick={props.onClick}>
            Cancel
          </button>
        </div>
      </div>
      <ConfirmAddressModal show={show} onShow={() => onModalOpen()}/>
    </>
  );
};

export default MailingAddress;
